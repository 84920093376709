import ReactPDF from "@react-pdf/renderer";
import PdfText from "../pdf-text/pdf-text";
import style from "./pdf-included-services.style";
import { PdfIncludedServicesProps } from "./props/pdf-included-services.props";

export default function PdfIncludedServices(
  props: Readonly<PdfIncludedServicesProps>
) {
  const { Title, Text, pdfStyles } = props;
  return (
    <ReactPDF.View>
      <ReactPDF.Text style={{...style.title, color: props.pdfStyles.primaryColor700 ?? "#000000"}}>{Title}</ReactPDF.Text>
      <ReactPDF.View style={style.productList}>
        <PdfText Text={Text} style={style.productList} pdfStyles={pdfStyles} />
      </ReactPDF.View>
    </ReactPDF.View>
  );
}
