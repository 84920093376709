import ComponentModelRs from "../dto/component/response/component-model-rs";
import Component from "../models/component";
import HeaderMock from "../props/mocks/header.mock";
import MobileHeaderMock from "../props/mocks/mobile-header.mock";

const HEADER_TRAVELTOOL = 2;

export default function getComponentModel(
  component: Component,
  models: ComponentModelRs[],
  isMobile: boolean,
  environment: string
) {
  const componentModel = models?.find(
    (model) => model.componentCode === component?.code
  );
  let model: {
    id: string;
    key: string;
    html: string;
    props: object | undefined;
  } = { id: "", key: "", html: "", props: undefined };
  if (componentModel?.model && component.id === HEADER_TRAVELTOOL) {
    if (environment === "development") {
      if (isMobile) {
        model.props = new MobileHeaderMock();
      } else {
        model.props = new HeaderMock()
      }
    } else {
      let apiModel = JSON.parse(componentModel?.model);
      if (apiModel?.data) {
        apiModel = JSON.parse(apiModel.data as string);
      }
      model.props = apiModel?.data;
    }
  } else if (
    componentModel?.model !== undefined &&
    componentModel?.model !== ""
  ) {
    model = JSON.parse(componentModel?.model);
  }
  model.id = component.name;
  model.key = component.code;
  if (component.html) {
    model.html = component.html;
  }
  return model;
}