import React from "react";
import { FlightProps } from "../props/flight.props";
import FlightContentCard from "./flight-content-card";
import "./flight-card.css";

export default function FlightCard(props: Readonly<FlightProps>) {
  const {
    airlineLogo,
    arrival,
    departure,
    airlineName,
    flightNumber,
    flightTime,
  } = props;
  return (
    <div className="flight-card">
      <div className="flight-card__header">
        <div className="flight-card__header-main">
          <img
            alt={airlineName}
            src={airlineLogo}
            className="flight-card__carrier-logo"
          />
          <div className="flight-card__header-carrier">
            <div className="flight-card__header-carrier-name">
              {airlineName}
            </div>
          </div>
        </div>
        <div className="flight-card__header-actions">
          <div className="flight-card__header-actions-code">{flightNumber}</div>
        </div>
      </div>
      <div className="flight-card__content">
        <FlightContentCard
          airport={departure.airport}
          city={departure.city}
          country={departure.country}
          date={departure.date}
          hour={departure.hour}
        />
        <div className="flight-card__content-middle">
          <div className="flight-card__content-middle-duration">
            {flightTime}
          </div>
          <i className="ri-arrow-right-line" />
        </div>
        <FlightContentCard
          airport={arrival.airport}
          city={arrival.city}
          country={arrival.country}
          date={arrival.date}
          hour={arrival.hour}
          className="destination"
        />
      </div>
    </div>
  );
}
