/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React from "react";
import DOMPurify from "isomorphic-dompurify";
import "./scss/main.scss";

type FooterTraveltoolProps = {
  html: string;
};

export default function FooterTraveltool({ ...props }: FooterTraveltoolProps) {
  return (
    <div className="footer-traveltool">
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.html) }} />
    </div>
  );
}
