import { ImageProps } from "../header/image.props";
import { ConfigProps } from "../mobile-header/config.props";
import { MenuProps } from "../mobile-header/menu.props";
import { MobileHeaderProps } from "../mobile-header/mobile-header.props";
import { OptionsProps } from "../mobile-header/options/options.props";
import { PhoneProps } from "../mobile-header/phone.props";
import { UserProps } from "../mobile-header/user/user.props";
import { ItemMenuProps } from "../mobile-header/item-menu.props";
import { PointOfSaleProps } from "../header/agency/point-of-sale.props";

export default class MobileHeaderMock implements MobileHeaderProps {
  config: ConfigProps;

  agency: PointOfSaleProps;

  logo: ImageProps;

  phone: PhoneProps;

  user: UserProps;

  menu: MenuProps;

  options: OptionsProps;

  constructor() {
    const setSubmenuFunc = (value: ItemMenuProps) => {
      return value;
    };
    this.config = {
      literals: {
        chooseDestinationLiteral: "",
        configurationLiteral: "",
        createAccountLiteral: "",
        currencyLiteral: "",
        emailLiteral: "",
        languageLiteral: "",
        loadingLiteral: "",
        locationLiteral: "",
        loginLiteral: "",
        logOutLiteral: "",
        menuLiteral: "",
        passwordLiteral: "",
        profileLiteral: "",
        titleAgent: "",
        titleCurrencyLiteral: "",
        titleLanguageLiteral: "",
        welcomeLiteral: "",
        withoutAccountLiteral: "",
      },
    };
    this.agency = {
      address: "",
      city: "",
      icon: "",
      name: "",
      postalCode: "",
    };

    this.options = {
      currencies: {
        items: [],
      },
      languages: {
        items: [],
      },
      locations: {
        items: [],
      },
    };
    this.phone = {
      href: "",
      target: "",
      number: "",
      title: "",
    };
    this.user = {
      unlogged: {
        login: {
          link: {
            href: "",
            content: "",
            title: "",
          },
        },
        create_account: {
          link: {
            href: "",
            content: "",
            title: "",
          },
        },
        dataProtection: {
          content: "",
          title: "",
          link: {
            href: "",
          },
        },
        infoDataProtection: [
          {
            content: "",
            title: "",
          },
          {
            content: "",
            title: "",
          },
          {
            content: "",
            title: "",
          },
        ],
      },
    };
    this.menu = {
      background: {
        alt: "",
        src: "",
        link: "",
      },
      logo: {
        alt: "",
        src: "",
        link: "",
      },
      items: [
        {
          link: {
            href: "https://producto.traveltooleservices.es",
            title: "Exclusivos",
            content: "Exclusivos",
          },
          setSubitems: setSubmenuFunc,
        },
        {
          content: "Paquetes",
          goToProductPageLiteral: "Ir a Paquetes",
          itemGroups: [{
              title: "Nuestros Paquetes",
              items: [
                {
                  link: {
                    href: "/vuelo-hotel/",
                    title: "Vuelo + Hotel",
                    content: "Vuelo + Hotel",
                  },
                },
                {
                  link: {
                    href: "/tren-hotel/",
                    title: "Tren + Hotel",
                    content: "Tren + Hotel",
                  },
                },
                {
                  link: {
                    href: "/ferry-hotel/",
                    title: "Ferry + Hotel",
                    content: "Ferry + Hotel",
                  },
                },
              ]
            }
          ],
          link: {
            href: "/viajes",
            title: "Viajes",
            content: "Viajes"
          },
          setSubitems: setSubmenuFunc,
        },
      ],
      
    };

    this.logo = {
      src: "https://cdn.logitravel.com/contenidosshared/franquicias/logos/5240/logocabeceraagencia.jpg",
      alt: "Traveltool E-services",
      link: "#",
    };
  }
}
