import { Text, View } from "@react-pdf/renderer";
import style from "./pdf-title.style";
import { TitleProps } from "../../../title/props/title.props";

export default function PdfTitle({ Title, pdfStyles }: Readonly<TitleProps>) {
  return (
    <View>
      <Text style={{...style.title, color: pdfStyles.primaryColor700 ?? "#000000"}}>{Title}</Text>
    </View>
  );
}
