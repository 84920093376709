import { StyleSheet } from "@react-pdf/renderer";

const style = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
  },
  textEllipsis: {
    color: "#1f2e5c",
    textOverflow: "ellipsis",
    maxLines: 1,
    fontSize: 10,
  },
  googleImage: {
    width: 200,
    height: 100,
    borderRadius: 10,
    marginRight: 10,
    backgroundColor: "#e6e9ed",
  },
  dataContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  titleWithRatingContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  ratingNumber: {
    marginRight: 5,
    color: "#64748B",
  },
  centerWithGap: {
    alignItems: "center",
    gap: 5,
  },
});

export default style;
