import { Image } from "./../itinerary/props/itinerary.props";
import { ProposalImagesProps } from "./props/proposal-images.props"
import "./proposal-images.css"
import ProposalImageItem from "./proposal-image-item/proposal-image-item";

export default function ProposalImages({images} : Readonly<ProposalImagesProps>) {

    const count = images.length;

    return (
        <div className="proposal-images" data-testid="proposal-images">
            {BuildMain(images[0], count, images)}
            {BuildSecondary(images)}
        </div>
    );
}

const BuildSecondary = (images: Image[]) => {
    const originalImages = images;
    [,...images] = images;

    if (images.length === 0) return;

    const count = images.length;

    const topClassName = count === 1 ? 
        'proposal-images__secondary-top solo' :
        'proposal-images__secondary-top'
    
    return (
        <div className="proposal-images__secondary">
            <div className={topClassName}>
                <ProposalImageItem 
                    image={images[0]}
                    images={originalImages}
                    className={''}
                    count={0}
                />
            </div>
            {BuildSecondaryBottom(images, originalImages)}
        </div>
        
    );
}

const BuildSecondaryBottom = (images: Image[], originalImages: Image[]) => {
    [,...images] = images;

    images = images.slice(0,2);

    if (images.length === 0) return;

    return (
        <div className="proposal-images__secondary-bottom">
            {images.map(x => 
                <ProposalImageItem 
                    key={x.id}
                    image={x}
                    images={originalImages}
                    className={''}
                    count={0}
                />)
            }
        </div>
        
    );
}

const BuildMain = (image: Image, count: number, images: Image[]) => {

    let className =  count === 1 ? 'proposal-images__main full-width'
    : 'proposal-images__main';

    if (count === 2) 
        className = `${className} half-width`;

    return <ProposalImageItem  
                image={image}
                images={images}
                className={className}
                count={count}
            />;
}