import { StyleSheet } from "@react-pdf/renderer";

const defaultColor = "#1F2E5C";
const defaultBackgroundColor = "none";

const style = StyleSheet.create({
  html: {
    fontSize: 10,
    color: "#152b5b",
  },
  bulletOverrides: {
    paddingRight: 4,
  },
  contentOverrides: {
    flexGrow: 1,
    flexBasis: 0,
  },
});

const stylesheet = {
  ["*"]: {
    color: defaultColor,
  },
  a: {
    color: "#0000FF",
  },
  table: {
    border: "1px solid #e2e8f0",
  },
  [".table table tbody tr:nth-child(2n)"]: {
    backgroundColor: "#f8fafc",
  },
  td: {
    padding: "4px",
    backgroundColor: defaultBackgroundColor,
  },
  th: {
    padding: "4px",
    backgroundColor: defaultBackgroundColor,
  },
  [".table ul"]: {
    paddingLeft: "5px",
  },
  [".table li"]: {
    marginBottom: "3px",
  },
};

const setColor = (colorVar: string) => {
  stylesheet["*"].color = colorVar ?? defaultColor;
};

const setBackgroundColor = (colorVar: string) => {
  stylesheet.td.backgroundColor = colorVar ?? defaultBackgroundColor;
  stylesheet.th.backgroundColor = colorVar ?? defaultBackgroundColor;
};

export { style, stylesheet, setColor, setBackgroundColor };
