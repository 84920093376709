import { StyleSheet } from "@react-pdf/renderer";

const style = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  city: {
    color: "#94a3b8",
    fontSize: 10,
  },
  airport: {
    color: "#475569",
    fontSize: 16,
  },
  date: {
    color: "#64748b",
    fontSize: 10,
  },
  time: {
    color: "#94a3b8",
    fontSize: 14,
  },
});

export default style;
