import { ReactElement } from "react";
import { ActivitiesProps } from "./props/activities.props";
import "./activities.css";
import CurrencyHelper from "../../../../helpers/currency.helper";
import { ActivityProps } from "./props/activity.props";
import MoguText from "../text/text";

function Activity(props: Readonly<ActivityProps>) {
  const { amount, currency, description, image, priceTag, title } = props;
  return (
    <div className="activity">
      <div className="activity-image" style={{ backgroundImage: `url(${image})` }} />
      <div className="info">
        {priceTag && (
          <div className="price-tag">
            <div className="tag">{priceTag}</div>
          </div>
        )}
        <div className="title-with-price">
          <div className="activity-title">{title}</div>
          <div className="activity-price">
            {amount}&nbsp;
            {CurrencyHelper.formatCurrency(currency)}
          </div>
        </div>
        <MoguText
          style={{ marginTop: 16, fontSize: 14, lineHeight: "calc(22px)" }}
          Text={description}
        />
      </div>
    </div>
  );
}

export default function Activities(props: Readonly<ActivitiesProps>) {
  const { activities } = props;
  const activityElements: ReactElement[] = [];
  activities.forEach((activity: ActivityProps) => {
    activityElements.push(
      <Activity
        key={activity.id}
        id={activity.id}
        amount={activity.amount}
        currency={activity.currency}
        description={activity.description}
        image={activity.image}
        priceTag={activity.priceTag}
        title={activity.title}
      />
    );
  });
  return (
    <div className="activities" data-testid="activities">
      {activityElements}
    </div>
  );
}
