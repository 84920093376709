import { View, Text } from "@react-pdf/renderer";
import { AirportFlightProps } from "../../../../event/props/airport-flight.props";
import style from "./pdf-flight-content.style";

export default function PdfFlightContentCard(props: Readonly<AirportFlightProps>) {
  const { airport, city, className, country, date, hour } = props;
  return (
    <View
      style={{
        ...style.container,
        alignItems: className == "left" ? "flex-start" : "flex-end",
      }}
    >
      <Text style={style.city}>{city}</Text>
      <Text style={style.airport}>{airport}</Text>
      <Text style={style.city}>
        {city}, {country}
      </Text>
      <Text style={style.date}>{date}</Text>
      <Text style={style.time}>{hour}</Text>
    </View>
  );
}
