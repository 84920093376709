import ImageUrlProxyUtils from "../../../../utils/imageUrlProxy.utils";
import { CardPriceProposal, ProductInfoProps } from "../product-card/props/product-card.props";
import './banner.css';

export default function Banner({ ...props }: Readonly<ProductInfoProps>) {

  const {
    ImageUrl,
    Title,
    NumDays,
    ProposalPrice,
  } = props;

  let priceFromToString: { [id: number]: string } = {};
  priceFromToString[CardPriceProposal.from] = ProposalPrice.split(' ')[0] ?? '';
  priceFromToString[CardPriceProposal.to] = ProposalPrice.replace(priceFromToString[CardPriceProposal.from], '') ?? '';

  return (
    <div data-testid="banner" id="banner">
      <img className={"carousel-image"} src={ImageUrlProxyUtils.processImageUrl(ImageUrl)} alt="banner image" />
      <div className="aligner">
        <div className="product-info">
          <div className="info">
            <h2>{Title}</h2>
            <h4>{NumDays}</h4>
          </div>
          {priceFromToString[CardPriceProposal.to].trim() != '' &&
            <div className="price-info">
              <h4>{priceFromToString[CardPriceProposal.from]}</h4>
              <h2>{priceFromToString[CardPriceProposal.to]}</h2>
            </div>}
        </div>
      </div>
    </div>
  );
}