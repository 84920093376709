import axios from "axios";
import UpdatedSessionRs from "../models/api/updated-session-rs";

export default class SessionService {
  static async setOriginAirportInSession(
    originAirport: string
  ): Promise<UpdatedSessionRs> {
    const rs = await axios.post<UpdatedSessionRs>(
      `/set-origin-airport/${originAirport}`,
      {},
      {
        withCredentials: false,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET",
        },
      }
    );

    return rs.data;
  }
}
