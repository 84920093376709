type QuestionInputProps = {
  label: string;
  id: string;
  required: boolean;
  type: string;
  callBack: (type: string, value: string) => void;
};

export default function QuestionInput({
  label,
  id,
  required,
  type,
  callBack,
}: Readonly<QuestionInputProps>) {

  return (
    <div className="mogu-input">
      <div className="mogu-input-label">
        <label className="question-label" aria-label={label} htmlFor={id}>
          {label}
        </label>
        <div className="form-question__required-indicator">*</div>
      </div>
      <div className="mogu-input__container">
        <input
          required={required}
          id={id}
          data-testid="email-input"
          type={type == "email" ? "email" : "text"}
          onChange={(e) => callBack(type, e.target.value)}
        />
      </div>
    </div>
  );
}
