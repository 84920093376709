import { AgencyProps } from "../header/agency.props";
import { HeaderProps, WithOrderAndVisibleProps } from "../header/header.props";
import { IconProps } from "../header/icon.props";
import { ImageProps } from "../header/image.props";
import { ItemProps } from "../header/item.props";
import { MenuProps } from "../header/menu.props";
import { OriginProps } from "../header/origin/origin.props";
import { StylesProps } from "../header/styles.props";

export default class HeaderMock implements HeaderProps {
  menu: MenuProps;

  logo: WithOrderAndVisibleProps<ImageProps>;

  agency: AgencyProps;

  myAccount: ItemProps;

  origin: OriginProps;

  socialIcons: IconProps[];

  styles: StylesProps;

  constructor() {
    this.menu = {
      agency: [
        {
          title: "¿Quiénes somos?",
          url: "/contents/whoweare",
          subitems: [
            {
              title: "Quiénes somos",
              url: "/contents/whoweare"
            }
          ]
        },
        {
          title: "Test",
          url: "",
        }
      ],
      products: {
        items: [
          {
            title: "Viajes",
            url: "",
            code: "",
            subitemGroups: [
              {
                title: "Nuestros Paquetes",
                subitems: [
                  {
                    title: "Vuelo + Hotel",
                    url: "/vuelo-hotel/",
                  },
                ],
              },
            ],
          },
          {
            title: "Destinos",
            url: "/destinos",
            code: "",
            subitemGroups: [
              {
                title: "Cerca de ti",
                subitems: [
                  {
                    title: "Tenerife",
                    url: "/viajes/europa/espana/tenerife/",
                  },
                ],
              },
            ],
          },
          {
            title: "Más productos",
            url: "",
            code: "tab_more_products",
            subitemGroups: [
              {
                title: "Vuelos",
                description:
                  "Date un vuelo sin sorpresas... y el precio final desde el inicio",
                url: "/flightsshowcase/",
              },
              {
                title: "Trenes",
                description: "Hay trenes que pasan una vez, y otra, y otra...",
                url: "/flightsshowcase/",
              },
              {
                title: "Ferries",
                description: "Elegimos el mejor ferry para tu bolsillo",
                url: "/flightsshowcase/",
              },
            ],
          },
        ],
      },
      literal: "Viajes"
    };

    this.logo = {
      order: 1,
      visible: true,
      data: {
        src: "https://cdn.logitravel.com/contenidosshared/franquicias/logos/5240/logocabeceraagencia.jpg",
        alt: "Traveltool E-services",
        link: "#",
      },
    };

    this.agency = {
      pointOfSale: {
        order: 2,
        visible: true,
        data: {
          address: "C/ Test",
          city: "Palma",
          icon: "",
          postalCode: "07121",
          name: "Traveltool E-Services",
        },
      },
      phone: {
        order: 0,
        visible: true,
        data: {
          icon: "phone",
          number: "971 599 000",
          title: "¡Llamanos!",
        },
      },
    };
    this.socialIcons = [
      {
        icon: "nico-facebook",
        link: {
          title: "Facebook",
          href: "https://www.facebook.com",
          target: "_blank",
        },
        style: ""
      },
      {
        icon: "nico-youtube",
        link: {
          title: "YouTube",
          href: "",
          target: "_blank",
        },
        style: ""
      },
    ];
    this.origin = {
      title: "Elige tu aeropuerto más cercano",
      selected: {
        title: "Madrid",
        value: "MAD",
        selected: true,
      },
      origins: {
        originItemList: [
          {
            selected: false,
            title: "Mallorca",
            value: "PMI",
          },
          {
            selected: false,
            title: "Barcelona",
            value: "BCN",
          },
          {
            selected: true,
            title: "Madrid",
            value: "MAD",
          },
        ],
      },
    };
    this.myAccount = {
      title: "Mi cuenta",
      url: "/mybookings/login",
    };
    this.styles = {
      background: {
        primary: "cyan",
        secondary: "green",
      },
      font: {
        style: "Verdana",
        color: {
          primary: "red",
          secondary: "black",
          tertiary: "purple",
        },
      },
    };
  }
}
