import { Suspense, useContext, useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import ProductNoLazyRenderer from '../renderers/product-no-lazy-renderer';
import PageContext from './context';
import ComponentModelRs from '../dto/component/response/component-model-rs';
import Page from '../models/page';
import CssUtils from '../utils/css.utils';
import "../styles/Home.css";
import "../styles/Home.scss";
import "./Product.css";
import ProductError from '../components/product/error';

type ProductProps = {
  expired?: boolean;
};

function Product({ ...props }: Readonly<ProductProps>) {
  const pageWithModels = useContext(PageContext);
  const [helmet, setHelmet] = useState<any>(null);
  const errorImage = "https://www.traveltool.es/traveltool/images/product-error.png";
  const errorText = "Este producto no está disponible";

  useEffect(() => {
    setHelmet(
      <Helmet>
        <link
          rel="shortcut icon"
          href="https://cdn.traveltool.es/traveltool/images/favicon.ico"
          type="image/x-icon"
        />
      </Helmet>
    );
  }, [pageWithModels]);
  return (
    <Suspense>
      <main>
        <section
          style={CssUtils.injectPageCSSVariables(pageWithModels?.page as Page)}
        >
          {helmet}
          <div>
            {pageWithModels?.page && pageWithModels.page.layoutInstance
              ? pageWithModels.page.layoutInstance.componentInstanceLayoutInstances.map(
                (componentInstanceLayoutInstance) => (

                  <div
                    key={
                      componentInstanceLayoutInstance.componentInstance.component
                        .code
                    }
                    id={
                      componentInstanceLayoutInstance.componentInstance.component
                        .code
                    }
                    style={CssUtils.injectComponentCSSVariables(
                      componentInstanceLayoutInstance.componentInstance
                    )}
                  >
                    {componentInstanceLayoutInstance.componentInstance.component
                      .code == "product" && (props.expired || !pageWithModels.models?.find(m => m.componentCode == "product")?.success) ?
                      <ProductError errorImage={errorImage} errorText={errorText} />
                      :
                      ProductNoLazyRenderer(
                        {
                          componentInstance: componentInstanceLayoutInstance.componentInstance,
                          models: pageWithModels.models as ComponentModelRs[],
                          environment: pageWithModels.environment as string
                        }
                      )
                    }
                  </div>
                )
              )
              : 
              <ProductError errorImage={errorImage} errorText={errorText} />
              }
          </div>
        </section>
      </main>
    </Suspense>
  );
}

export default Product;