import { Text, View } from "@react-pdf/renderer";
import style from "./pdf-section.style";
import { PdfSectionProps } from "./props/pdf-section.props";

export default function PdfSection({
  title,
  pdfStyles,
}: Readonly<PdfSectionProps>) {
  return (
    <View style={style.container}>
      <Text style={{...style.text, color: pdfStyles.primaryColor700 ?? "#000000"}}>{title}</Text>
    </View>
  );
}
