import { View } from "@react-pdf/renderer";
import PdfPrice from "../pdf-price/pdf-price";
import style from "./pdf-activities.style";
import { PdfActivitiesProps } from "./props/pdf-activities.props";
import { PdfActivityProps } from "./props/pdf-activity.props";
import { PdfStyle } from "../../../../props/side-menu.props";

export default function PdfActivities({
  activities,
  tertiaryColor500,
}: Readonly<PdfActivitiesProps>) {
  return (
    <View>
      {activities.map((x) => (
        <PdfActivity
          id={x.id}
          key={x.id}
          amount={x.amount}
          currency={x.currency}
          description={x.description}
          image={x.image}
          priceTag={x.priceTag}
          title={x.title}
          tertiaryColor500={tertiaryColor500}
        />
      ))}
    </View>
  );
}

function PdfActivity({
  amount,
  description,
  title,
}: Readonly<PdfActivityProps>) {
  return (
    <View style={style.activityContainer}>
      <PdfPrice
        amount={amount}
        description={description}
        title={title}
        pdfStyles={{} as PdfStyle}
      />
    </View>
  );
}
