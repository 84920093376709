import { StyleSheet } from "@react-pdf/renderer";

const style = StyleSheet.create({
  container: {
    maxHeight: 50,
    marginTop: 10,
    marginBottom: 30,
    gap: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "450px",
  },
  account: {
    fontSize: "16px",
    fontFamily: "Helvetica-Bold",
  },
  accountBox: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px"
  },
  iconTitle: {
    width: 14,
    height: 14,
    marginRight: 5,
  },
  titleBox: {
    paddingTop: "10px",
    display: "flex",
    flexDirection: "row",
    gap: 5,
  },
  imageBox: {
    display: "flex",
    flex: "1  20%",
    flexDirection: "row",
    paddingRight: "10px",
    gap: 15,
    width: "94px"
  },
  infoBox: {
    paddingTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: 15
  },
  info: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "5px"
  },
  infoAgent: {
    marginBottom: "20px"
  },
  infoTitle: {
    marginBottom: "20px",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold"
  },
  titleImage: {
    height: "auto",
    maxwidth: "100%",
    borderRadius: "10px",
    backgroundColor: "#e6e9ed",
    flex: "1 0 30%",
  },
});

export default style;