import Html from "react-pdf-html";
import {
  style,
  stylesheet,
  setColor,
  setBackgroundColor,
} from "./pdf-text.style";
import {
  renderCustomFigure,
  renderCustomLi,
} from "./pdf-text-custom-renderers";
import { PdfTextProps } from "./props/pdf-text.props";

export default function PdfText(props: Readonly<PdfTextProps>) {
  const text = props.Text;
  const { pdfStyles } = props;
  setColor(pdfStyles?.primaryColor700);
  setBackgroundColor(pdfStyles?.secondaryColor300);
  return (
    <Html
      style={style.html}
      renderers={{
        figure: renderCustomFigure,
        li: renderCustomLi(style.bulletOverrides, style.contentOverrides),
      }}
      stylesheet={stylesheet}
    >
      {text}
    </Html>
  );
}
