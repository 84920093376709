import React, { ReactElement, createRef, useContext, useEffect } from "react";
import ProductRenderer from "../../renderers/product-renderer";
import { BaseModel } from "./props/base-model.props";
import "./product.css";
import SideMenu from "./subcomponents/side-menu/side-menu";
import { SideMenuProps } from "./props/side-menu.props";
import { ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import { SubcomponentProps } from "./props/subcomponent.props";
import PageContext from "../../client/context";
import SideMenuMobile from "./subcomponents/side-menu-mobile/side-menu-mobile";

export default function Product(props: Readonly<BaseModel>) {
  const context = useContext(PageContext);
  const refsMap: Map<string, React.MutableRefObject<any>> = new Map<
    string,
    React.MutableRefObject<any>
  >();
  
  useEffect(() => {
    if (typeof window !== "undefined") {
      injectStyle();
    }
  }, []);

  const { data } = props;
  if (!data) {
    return <div />;
  }
  const parsedModel = JSON.parse(data as unknown as string);
  const subcomponentProps =
    parsedModel.subComponentModels as SubcomponentProps[];
  const sideMenu = parsedModel.sideMenu as SideMenuProps;
  sideMenu.settings = parsedModel.settings;
  const token = parsedModel.token as string;
  const { code, literals, minPrice, sections, title, leadForm, pdfStyles, settings } =
    sideMenu;

  const productSubcomponents: ReactElement[] = [];
  
  if (subcomponentProps) {
    subcomponentProps.forEach((subcomponent) => {
      const ref = createRef<HTMLElement>();
      const product = ProductRenderer(subcomponent, ref);
      if (product) {
        refsMap.set(subcomponent.id, ref);
        productSubcomponents.push(product);
      }
    });
  }
  return (
    <div>
      {productSubcomponents[0]}
      <div id="product-container" className="product-container" data-testid="product">
        <div className="product-sidebar">
        {context?.device?.isMobileDevice || context?.device?.isTablet ? (
          <SideMenuMobile
          code={code}
          literals={literals}
          minPrice={minPrice}
          title={title}
          leadForm={leadForm}
          subComponentModels={subcomponentProps}
          pdfStyles={pdfStyles}
          settings={settings}
          token={token}
        />
          ) : 
          <SideMenu
          code={code}
          literals={literals}
          minPrice={minPrice}
          refsMap={refsMap}
          sections={sections}
          title={title}
          leadForm={leadForm}
          subComponentModels={subcomponentProps}
          pdfStyles={pdfStyles}
          settings={settings}
          token={token}
        /> }
          
        </div>

        <div className="product-content">
        <div className="product-content-body">
           {productSubcomponents.slice(1)}
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}
