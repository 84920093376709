import classnames from 'classnames';
import './pagination.scss';
import { usePagination, DOTS } from '../../hooks/pagination/usePagination';
import { PaginationProps } from './props/pagination.props';

export default function Pagination (props: Readonly<PaginationProps>) {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={classnames('pagination-container', { [className]: className })} data-testid="pagination"
    >
      <button
        className={classnames('pagination-item', {
          disabled: currentPage === 1
        })}
        onClick={onPrevious} key="left" onKeyDown={onPrevious}
      >
        <div className="arrow left" />
      </button>
      {paginationRange.map(pageNumber => {
         
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots" key="dots">&#8230;</li>;
        }
		
        return (
          <button
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage
            })}
            onClick={() => onPageChange(pageNumber)} key = {pageNumber} onKeyDown={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </button>
        );
      })}
      <button
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage
        })}
        onClick={onNext} key="right" onKeyDown={onNext}
      >
        <div className="arrow right" />
      </button>
    </ul>
  );
};