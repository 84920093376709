import { Image, View, Text } from "@react-pdf/renderer";
import { HtmlStyle } from "react-pdf-html";
import { HtmlRenderer } from "react-pdf-html/dist/render";
import { isValidElement } from "react";
import { HtmlElement } from "react-pdf-html/dist/parse";
import {
  lowerAlpha,
  orderedAlpha,
  upperAlpha,
} from "react-pdf-html/dist/ordered.type";

const renderCustomFigure: HtmlRenderer = ({ style, children }) => {
  //Excluding oembed and others for now
  if (isValidElement(children) && children?.props?.element?.tag === "table")
    return <View style={style}>{children}</View>;
  else return null;
};

const renderCustomLi =
  (bulletOverrides: any, contentOverrides: any): HtmlRenderer =>
  ({ element, stylesheets, style, children }) => {
    const bulletStyles = stylesheets.map((stylesheet) => stylesheet.li_bullet);
    const contentStyles = stylesheets.map((stylesheet) => ({
      ...stylesheet.li_content,
      flexBasis: 0,
    }));

    const list: HtmlElement = element.closest("ol, ul") as HtmlElement;
    const ordered = list?.tag === "ol" || element.parentNode.tag === "ol";
    const listStyle =
      list?.style?.reduce(
        (combined, listStyle) => Object.assign(combined, listStyle),
        {} as HtmlStyle
      ) || {};
    const itemStyle = element.style.reduce(
      (combined, itemStyle) => Object.assign(combined, itemStyle),
      {} as HtmlStyle
    );
    const listStyleType =
      itemStyle.listStyleType ||
      itemStyle.listStyle ||
      listStyle.listStyleType ||
      listStyle.listStyle ||
      "";

    let bullet;
    if (listStyleType.includes("none")) {
      bullet = false;
    } else if (listStyleType.includes("url(")) {
      bullet = (
        <Image
          src={listStyleType.match(/\((.*?)\)/)[1].replace(/(['"])/g, "")}
        />
      );
    } else if (ordered) {
      if (lowerAlpha.includes(listStyleType)) {
        bullet = (
          <Text>{orderedAlpha[element.indexOfType].toLowerCase()}.</Text>
        );
      } else if (upperAlpha.includes(listStyleType)) {
        bullet = (
          <Text>{orderedAlpha[element.indexOfType].toUpperCase()}.</Text>
        );
      } else {
        bullet = <Text>{element.indexOfType + 1}.</Text>;
      }
    } else {
      bullet = <Text>•</Text>;
    }
    return (
      <View style={style}>
        {bullet && (
          <View style={{ ...bulletStyles, ...bulletOverrides }}>{bullet}</View>
        )}
        <View style={{ ...contentStyles, ...contentOverrides }}>
          {children}
        </View>
      </View>
    );
  };

export { renderCustomFigure, renderCustomLi };
