import { Document, Page } from "@react-pdf/renderer";
import { PdfProps } from "./props/pdf.props";
import PdfTop from "./components/pdf-top/pdf-top";
import React from "react";
import PdfHeaderImage from "./components/pdf-header-image/pdf-header-image";
import PdfBottom from "./components/pdf-bottom/pdf-bottom";
import style from "./pdf.style";
import PdfHeaderBasics from "./components/pdf-header-basics/pdf-header-basics";
import PdfText from "./components/pdf-text/pdf-text";
import PdfGallery from "./components/pdf-gallery/pdf-gallery";
import PdfTitle from "./components/pdf-title/pdf-title";
import PdfSection from "./components/pdf-section/pdf-section";
import PdfBox from "./components/pdf-box/pdf-box";
import PdfSpacer from "./components/pdf-spacer/pdf-spacer";
import PdfDivider from "./components/pdf-divider/pdf-divider";
import PdfPrice from "./components/pdf-price/pdf-price";
import PdfPrices from "./components/pdf-prices/pdf-prices";
import PdfActivities from "./components/pdf-activities/pdf-activities";
import PdfItinerary from "./components/pdf-itinerary/pdf-itinerary";
import PdfEvent from "./components/pdf-event/pdf-event";
import { HeaderBasicsProps } from "../header-basics/props/header-basics.props";
import { PdfTopProps } from "./components/pdf-top/props/pdf-top.props";
import PdfLogo from "./components/pdf-logo/pdf-logo";
import PdfContact from "./components/pdf-contact/pdf-contact";
import PdfIncludedServices from "./components/pdf-included-services/pdf-included-services";
import PdfNotIncludedServices from "./components/pdf-not-included-services/pdf-not-included-services";

const components = {
  "header-image": PdfHeaderImage,
  "header-basics": PdfHeaderBasics,
  title: PdfTitle,
  section: PdfSection,
  box: PdfBox,
  text: PdfText,
  gallery: PdfGallery,
  included_services: PdfIncludedServices,
  not_included_services: PdfNotIncludedServices,
  pdf_spacer: PdfSpacer,
  divider: PdfDivider,
  price: PdfPrice,
  prices: PdfPrices,
  activities: PdfActivities,
  itinerary: PdfItinerary,
  event: PdfEvent,
  pdfContact: PdfContact
};

const MyDoc = ({ subComponentModels, pdfStyles, settings }: Readonly<PdfProps>) => {
  const contact = subComponentModels.find((x) => x.name === "pdfContact")?.data as
  | PdfTopProps
  | undefined;
  const headerBasics = subComponentModels.find(
    (x) => x.name === "header-basics"
  )?.data as HeaderBasicsProps | undefined;
  return (
      <Document data-testid="pdf">
        <Page wrap style={style.body}>
          { settings.has_proposal_pdf_contact_header ? <PdfTop
            name={contact?.name}
            phone={contact?.phone}
            secondaryColor600={pdfStyles.secondaryColor600}
          /> : null}
          {headerBasics?.BrandUrl && <PdfLogo logoUrl={headerBasics!.BrandUrl} />}
          {subComponentModels.map((x) => {
            const ReactComponent = components[x.name];
            if (ReactComponent)
              return React.createElement(ReactComponent, {
                ...x.data,
                pdfStyles,
                key: x.id,
              });
            else return null;
          })}
          <PdfBottom
            productName={headerBasics?.Title}
            days={headerBasics?.NumDays}
            secondaryColor600={pdfStyles.secondaryColor600}
          />
        </Page>
      </Document>
  );
};

export default MyDoc;
