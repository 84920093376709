import { StyleSheet } from "@react-pdf/renderer";

const style = StyleSheet.create({
  container: {
    borderRadius: 8,
    padding: "15px 22.5px",
  },
  title: {
    color: '#1F2E5C',
  },
});

export default style;
