import CurrencyHelper from "../../../../helpers/currency.helper";
import { PriceProps } from "../price/props/price.props";
import MoguText from "../text/text";

export default function BasePrice(props: Readonly<PriceProps>) {
  const { amount, bookButtonLiteral, currency, description, link, title } =
    props;

  return (
    <div>
      <div className="main-price-row">
        <div className="price-title">
          <div className="title-text">{title}</div>
          <div className="price-with-currency">
            {amount} {CurrencyHelper.formatCurrency(currency)}
          </div>
        </div>
        {link && (
          <button
            type="button"
            id={`mogu-checkout_button_${link.id}`}
            className="mogu__checkout-button button price-button checkout-button"
            data-tour={link.id}
            data-url={`https://app.moguplatform.com/checkout_embed?code=${link.id}`}
          >
            <div className="button__inner">{bookButtonLiteral}</div>
          </button>
        )}
      </div>
      <MoguText
        style={{ marginTop: 16, fontSize: 14, lineHeight: "calc(22px)" }}
        Text={description}
      />
    </div>
  );
}
