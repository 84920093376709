const CurrencyHelper = {
  formatCurrency(currrencyValue: string): string {
    const currencyMap = {
      EUR: "€",
    };
    return currencyMap[currrencyValue];
  },
};

export default CurrencyHelper;
