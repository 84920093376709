import { StyleSheet } from "@react-pdf/renderer";

const style = StyleSheet.create({
  bottom: {
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    color: "white",
    paddingVertical: 10,
    paddingHorizontal: 50,
    fontSize: 10,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    minWidth: "100%",
  },
})

export default style;