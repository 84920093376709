import { BoxProps } from "./props/box.props";
import  MoguText  from "../text/text";
import "./box.css"

export default function Box({Title, Text, Color}: Readonly<BoxProps>) {
    return (
        <div className="mogu-box" data-testid="box" style={{backgroundColor: Color + BoxProps.MoguTransparencyHEX}}>
            <div className="box-title">
                <h4>{Title}</h4>
            </div>
            <MoguText Text={Text}></MoguText>        
        </div>
    );
}
