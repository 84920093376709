import { StyleSheet } from "@react-pdf/renderer";

const style = StyleSheet.create({
  title: {
    fontWeight: 500,
    fontSize: "13px",
    marginBottom: "2px",
    marginTop: "2px",
    color: "#11bba4b9",
  },
  productList: {
    marginLeft: "15px",
    fontSize: "10px",
    lineHeight: "1.5px"
  }
});

export default style;
