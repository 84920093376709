import React, { ForwardedRef, forwardRef } from "react";
import { SectionBlockProps } from "./props/section-block.props";

export default forwardRef<HTMLElement, SectionBlockProps>(function SectionBlock(
  { Data, ReactComponent }: Readonly<SectionBlockProps>,
  ref: ForwardedRef<any>
) {
  return (
    <div className="container-block">
      <div className="block" ref={ref}>
        {React.createElement(ReactComponent, Data)}
      </div>
    </div>
  );
}
);