import { CSSProperties } from "react";
import Application from "../models/application";
import ComponentInstance from "../models/component-instance";
import Page from "../models/page";
import StylesheetInstance from "../models/stylesheet-instance";

class CssUtils {
  static injectCSSVariables = (
    stylesheetInstances: StylesheetInstance[]
  ): CSSProperties => {
    const cssVariables = {};
    stylesheetInstances.forEach((stylesheetInstance) => {
      cssVariables[stylesheetInstance.styleVariable.name] =
        stylesheetInstance.value;
    });

    return cssVariables;
  };

  static injectComponentCSSVariables = (
    componentInstance: ComponentInstance
  ): CSSProperties => {
    const { stylesheetInstances } = componentInstance;
    return CssUtils.injectCSSVariables(stylesheetInstances);
  };

  static injectPageCSSVariables = (page: Page): CSSProperties => {
    const { stylesheetInstances } = page;
    return CssUtils.injectCSSVariables(stylesheetInstances);
  };

  static injectApplicationCSSVariables = (
    application: Application
  ): CSSProperties => {
    const { stylesheetInstances } = application;
    return CssUtils.injectCSSVariables(stylesheetInstances);
  };
}

export default CssUtils;
