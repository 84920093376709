import { Text, View } from "@react-pdf/renderer";
import style from "./pdf-bottom.style";
import { PdfBottomProps } from "./props/pdf-bottom.props";

export default function PdfBottom({
  productName,
  days,
  secondaryColor600,
}: Readonly<PdfBottomProps>) {
  return (
    <View
      fixed
      style={{
        ...style.bottom,
        backgroundColor: secondaryColor600 ?? "#150A44",
      }}
    >
      {productName && days && (
        <Text>
          {productName} | {days}
        </Text>
      )}
      <Text
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      />
    </View>
  );
}
