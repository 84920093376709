import { GalleryItemProps } from "./props/gallery-item.props";
import { createPortal } from "react-dom";
import ImagesModal from "../../images-modal/images-modal";
import "./gallery-item.css";
import useModalState from "../../custom-hooks/use-modal-state";
import ImageUrlProxyUtils from "../../../../../utils/imageUrlProxy.utils";

export default function GalleryItem({
  gallery,
  galleryItem,
  imgHeight,
}: Readonly<GalleryItemProps>) {
  const style = {
    backgroundImage: `url(${ImageUrlProxyUtils.processImageUrl(galleryItem.image_url)})`,
    height: imgHeight,
  };

  const [showModal, { createModal, closeModal }] = useModalState();

  return (
    <>
      <button
        type="button"
        onClick={createModal}
        onKeyDown={createModal}
        key={galleryItem.id}
        style={style}
        className="gallery-image"
        data-testid="gallery-item"
      />
      {showModal &&
        createPortal(
          <ImagesModal
            key={galleryItem.id}
            onClose={() => closeModal()}
            images={gallery.Items}
            selectedImageIndex={gallery.Items.findIndex(
              (x) => x.id === galleryItem.id
            )}
          />,
          document.body
        )}
    </>
  );
}
