import React from "react";
import "../styles/404.css";

export default function ErrorPage() {
  return (
    <div className="contenido">
      <div className="cTextError">
        <div className="container">
          <div className="row row-narrow">
            <div>
              <h1 className="visible shadow-white">¡Oops!</h1>
              <div className="div404">
                <span className="span404 titulo x2 likeHtag">
                  La página solicitada no existe.
                </span>
              </div>
            </div>
            <div>
              <img
                className="mt-sm-10"
                src="https://cdn.traveltool.es/traveltool/images2012/logis/oops.png"
                alt="Traveltool, página no encontrada"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}