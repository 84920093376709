import React, { ReactElement } from "react";
import { SubcomponentProps } from "../components/product/props/subcomponent.props";
import Price from "../components/product/subcomponents/price/price";
import Prices from "../components/product/subcomponents/prices/prices";
import sectionBlock from "../components/product/subcomponents/section-block/section-block";
import Video from "../components/product/subcomponents/video/video";
import headerImage from "../components/product/subcomponents/header-image/header-image";
import Title from "../components/product/subcomponents/title/title";
import MoguText from "../components/product/subcomponents/text/text";
import Gallery from "../components/product/subcomponents/gallery/gallery";
import section from "../components/product/subcomponents/section/section";
import Map from "../components/product/subcomponents/map/map";
import Box from "../components/product/subcomponents/box/box";
import Divider from "../components/product/subcomponents/divider/divider";
import headerBasics from "../components/product/subcomponents/header-basics/header-basics";
import Itinerary from "../components/product/subcomponents/itinerary/itinerary";
import Activities from "../components/product/subcomponents/activities/activities";
import File from "../components/product/subcomponents/file/file";
import Event from "../components/product/subcomponents/event/event";
import NotIncludedServices from "../components/product/subcomponents/not-included-services/not-included-services";
import IncludedServices from "../components/product/subcomponents/included-services/included-services";
import Contact from "../components/product/subcomponents/contact/contact";

const desktopComponents = {
  section,
  "header-image": headerImage,
  title: Title,
  "text": MoguText,
  map: Map,
  box: Box,
  "section-block": sectionBlock,
  divider: Divider,
  "header-basics": headerBasics,
  gallery: Gallery,
  price: Price,
  prices: Prices,
  included_services: IncludedServices,
  itinerary: Itinerary,
  not_included_services: NotIncludedServices,
  video: Video,
  activities: Activities,
  file: File,
  event: Event,
  contact: Contact
};

const componentsWithoutContainerBlock = ["header-image", "header-basics"];

function ProductRenderer(
  subcomponentProp: SubcomponentProps,
  ref: React.RefObject<HTMLElement>
): ReactElement {
  const { data, name } = subcomponentProp;
  const reactComponent = desktopComponents[name];
  if (reactComponent) {
    const reactElement = !componentsWithoutContainerBlock.includes(name)
      ? React.createElement(desktopComponents["section-block"] as any, {
        ref,
        ...{ Data: data, ReactComponent: reactComponent },
      })
      : React.createElement(reactComponent, {
        ref,
        ...data,
      });
    return reactElement;
  }
  return React.createElement("div", {});
}

export default ProductRenderer;
