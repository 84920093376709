import DOMPurify from "isomorphic-dompurify";
import { TextProps } from "./props/text.props";
import "./text.css";

export default function MoguText({ Text, style }: Readonly<TextProps>) {

  const allowedTags = {
    ALLOWED_TAGS: [
      'p',
      'strong',
      'i',
      'a',
      'ol',
      'li',
      'ul',
      'figure',
      'div',
      'oembed',
      'table',
      'tbody',
      'thead',
      'tr',
      'th',
      'td',
      'iframe',
      'br'
    ]
  }

  return (
        <div style={style} className="text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Text, allowedTags)}}>
        </div>
  );
}
