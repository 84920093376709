import { View, Text, Image } from "@react-pdf/renderer";
import PdfText from "../pdf-text/pdf-text";
import style from "./pdf-itinerary.style";
import PdfEvent from "../pdf-event/pdf-event";
import ImageUrlProxyUtils from "../../../../../../utils/imageUrlProxy.utils";
import { PdfItineraryProps } from "./props/pdf-itinerary.props";
import { PdfItineraryElementProps } from "./props/pdf-itinerary-element.props";

export default function PdfItinerary(props: Readonly<PdfItineraryProps>) {
  return (
    <View style={style.itinerariesContainer}>
      {props.Days.map((x) => (
        <PdfItineraryElement
          day={x}
          key={x.id}
          defaultExpanded={true}
          pdfStyles={props.pdfStyles}
        />
      ))}
    </View>
  );
}

function PdfItineraryElement({
  day,
  pdfStyles,
}: Readonly<PdfItineraryElementProps>) {
  return (
    <View style={style.itineraryContainer}>
      <Text style={style.itineraryTitle}>{day.title}</Text>
      {day.description && <PdfText Text={day.description} pdfStyles={pdfStyles}/>}
      {day.events?.map((x) => (
        <PdfEvent
          address={x.address}
          description={x.description}
          googleRatingsLiteral={x.googleRatingsLiteral}
          icon={x.icon}
          id={x.id}
          imageUrls={x.imageUrls?.map(image => ImageUrlProxyUtils.processImageUrl(image))}
          internationalPhone={x.internationalPhone}
          optional={x.optional}
          place={x.place}
          roomType={x.roomType}
          regime={x.regime}
          nights={x.nights}
          rating={x.rating}
          title={x.title}
          totalRatings={x.totalRatings}
          url={x.url}
          website={x.website}
          flight={x.flight}
          ship={x.ship}
          key={x.id}
          pdfStyles={pdfStyles}
        />
      ))}
      {day.images && day.images.length >= 1 && day.images[0].image_url && (
        <Image style={style.itineraryImage} src={ImageUrlProxyUtils.processImageUrl(day.images[0].image_url)} />
      )}
    </View>
  );
}
