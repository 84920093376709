import React, { ReactElement } from "react";
import { SideMenuProps } from "../../props/side-menu.props";
import "./side-menu.css";
import CurrencyHelper from "../../../../helpers/currency.helper";
import InterestedButton from "./interested-button/interested-button";
import ShareButton from "./share-button/share-button";
import DownloadPdfdButton from "./download-pdf-button/download-pdf-button";

export default function SideMenu(props: Readonly<SideMenuProps>) {
  const {
    code,
    literals,
    minPrice,
    refsMap,
    sections,
    title,
    leadForm,
    subComponentModels,
    pdfStyles,
    settings,
    token
  } = props;
  const scrollIntoSection = (id: string) => {
    const componentRef = refsMap.get(id);
    if (componentRef?.current) {
      componentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const clickHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    scrollIntoSection(event.currentTarget.id);
  };

  const keyboardHandler = (event: React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    scrollIntoSection(event.currentTarget.id);
  };

  const sectionElements: ReactElement[] = [];
  if (sections) {
    sections.forEach((section) => {
      sectionElements.push(
        <button
          type="button"
          key={section.id}
          id={section.id}
          onClick={clickHandler}
          onKeyUp={keyboardHandler}
          className="section-link"
        >
          <div className="arrow">
            <svg
              className="remixicon-icon "
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
            </svg>
          </div>
          <div className="section-name">{section.title}</div>
        </button>
      );
    });
  }

  
  return (
    <>
      <div className="menu-title">{title}</div>
      <div className="code" >{code}</div>
      <div className="nav-container">
       <div className="sections">{sectionElements}</div>
        <div className="actions">
          <button
            type="button"
            id={`mogu-checkout_button_${code}`}
            className="button mogu__checkout-button checkout-button"
            data-tour={code}
            data-url={`https://app.moguplatform.com/checkout_embed?code=${code}`}
          >
            {literals["Book"]} ({minPrice.amount}&nbsp;
            {CurrencyHelper.formatCurrency(minPrice.currency)})
          </button>
          <div className="button-divider"></div>
          {settings?.has_download_proposal ? <DownloadPdfdButton
            literals={literals}
            subComponentModels={subComponentModels}
            pdfStyles={pdfStyles}
            settings={settings}
          />: null}
          {leadForm ? (
            <InterestedButton
              id={leadForm.id}
              questions={leadForm.questions}
              title={leadForm.title}
              literals={literals}
            />
          ) : null}

          <ShareButton productName={title} literals={literals} token={token} />
        </div>
      </div>
    </>
  );
}
