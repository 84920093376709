import { Text, View, Image, Svg, Path } from "@react-pdf/renderer";
import { GooglePlace } from "../../../itinerary/props/itinerary.props";
import style from "./pdf-google-widget.style";
import { ReactElement } from "react";
import ImageUrlProxyUtils from "../../../../../../utils/imageUrlProxy.utils";

export default function PdfGoogleWidget(props: Readonly<GooglePlace>) {
  const img = props.photos?.[0];
  return (
    <View style={style.row}>
      {img && <Image style={style.googleImage} src={ImageUrlProxyUtils.processImageUrl(img)} />}
      <View style={style.dataContainer}>
        <TitleWithRating name={props.name} rating={props.rating} />
        {props.website && props.website != "" && BuildWeb(props.website)}
        {props.formatted_address && props.formatted_address != "" && BuilLocation(props.formatted_address)}
        {props.international_phone_number && props.international_phone_number != "" &&
          BuildPhone(props.international_phone_number)}
      </View>
    </View>
  );
}

type TitleWithRatingProps = {
  name: string;
  rating: number;
};

function TitleWithRating({ name, rating }: Readonly<TitleWithRatingProps>) {
  return (
    <View style={style.titleWithRatingContainer}>
      <Text style={{ color: "#1F2E5C" }}>{name}</Text>
      {rating > 0 && (
        <View style={style.row}>
          <Text style={style.ratingNumber}>{rating}</Text>
          {BuildRatingStars(rating, name)}
        </View>
      )}
    </View>
  );
}

const BuildRatingStars = (rating: number, name: string) => {
  if (rating === undefined || rating === null) return;

  const elements: ReactElement[] = [];

  for (let index = 0; index < 5; index++) {
    elements[index] = BuildRatingStar(index + 1 <= rating, index, name);
  }

  return elements;
};

const BuildRatingStar = (active: boolean, index: number, name: string) => {
  return (
    <Svg
      key={`ratingStar:${name}:${index}`}
      style={{ width: 12, height: 12 }}
      viewBox="0 0 24 24"
    >
      <Path
        fill={active ? "#FFD11A" : "#CBD5E1"}
        d="M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928z"
      />
    </Svg>
  );
};

const BuildWeb = (website: string) => {
  return (
    <View style={{ ...style.row, ...style.centerWithGap }}>
      <Svg style={{ width: 12, height: 12 }} viewBox="0 0 24 24">
        <Path
          fill="#195bf5"
          d="M6.235 6.453a8 8 0 0 0 8.817 12.944c.115-.75-.137-1.47-.24-1.722-.23-.56-.988-1.517-2.253-2.844-.338-.355-.316-.628-.195-1.437l.013-.091c.082-.554.22-.882 2.085-1.178.948-.15 1.197.228 1.542.753l.116.172c.328.48.571.59.938.756.165.075.37.17.645.325.652.373.652.794.652 1.716v.105c0 .391-.038.735-.098 1.034a8.002 8.002 0 0 0-3.105-12.341c-.553.373-1.312.902-1.577 1.265-.135.185-.327 1.132-.95 1.21-.162.02-.381.006-.613-.009-.622-.04-1.472-.095-1.744.644-.173.468-.203 1.74.356 2.4.09.105.107.3.046.519-.08.287-.241.462-.292.498-.096-.056-.288-.279-.419-.43-.313-.365-.705-.82-1.211-.96-.184-.051-.386-.093-.583-.135-.549-.115-1.17-.246-1.315-.554-.106-.226-.105-.537-.105-.865 0-.417 0-.888-.204-1.345a1.276 1.276 0 0 0-.306-.43zM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z"
        />
      </Svg>
      <Text style={style.textEllipsis}>{website}</Text>
    </View>
  );
};

const BuilLocation = (address: string) => {
  return (
    <View
      style={{
        ...style.row,
        ...style.centerWithGap,
      }}
    >
      <Svg style={{ width: 12, height: 12 }} viewBox="0 0 24 24">
        <Path
          fill="#195bf5"
          d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
        />
      </Svg>
      <Text style={style.textEllipsis}>{address}</Text>
    </View>
  );
};

const BuildPhone = (phone: string) => {
  return (
    <View style={{ ...style.row, ...style.centerWithGap }}>
      <Svg style={{ width: 12, height: 12 }} viewBox="0 0 24 24">
        <Path
          fill="#195bf5"
          d="M21 16.42v3.536a1 1 0 0 1-.93.998c-.437.03-.794.046-1.07.046-8.837 0-16-7.163-16-16 0-.276.015-.633.046-1.07A1 1 0 0 1 4.044 3H7.58a.5.5 0 0 1 .498.45c.023.23.044.413.064.552A13.901 13.901 0 0 0 9.35 8.003c.095.2.033.439-.147.567l-2.158 1.542a13.047 13.047 0 0 0 6.844 6.844l1.54-2.154a.462.462 0 0 1 .573-.149 13.901 13.901 0 0 0 4 1.205c.139.02.322.042.55.064a.5.5 0 0 1 .449.498z"
        />
      </Svg>
      <Text style={style.textEllipsis}>{phone}</Text>
    </View>
  );
};
