import React from 'react';
import DynamicRenderer from '../renderers/dynamic-renderer';
import ComponentModelRs from '../dto/component/response/component-model-rs';
import CssUtils from '../utils/css.utils'
import ComponentInstance from '../models/component-instance';

type ComponentProps = {
  name: string;
  componentInstance: ComponentInstance,
  models: ComponentModelRs[],
  environment: string
};

export default function SimpleComponent({ ...props }: ComponentProps) {
  return (
    <div data-testid="simple-component">
      {props && props.componentInstance
        ?     <div style={CssUtils.injectComponentCSSVariables(
                  props.componentInstance
                )}>
          {DynamicRenderer(
                  {
                    componentInstance: props.componentInstance,
                    models: props.models,
                    environment: props.environment 
                  }
                )}
              </div>
      : null}
      </div>
  );
}