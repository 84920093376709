import { GalleryProps } from "./props/gallery.props";
import "./gallery.css"
import GalleryItem from "./gallery-item/gallery-item";

export default function Gallery(gallery: Readonly<GalleryProps>) {

    const imgHeight = gallery.Items.length <= 2 ? 300 : 200;

    return (
        <div className="gallery-container" data-testid="gallery">
            {gallery.Items.map(x => <GalleryItem 
                                        key={x.id}
                                        galleryItem={x}  
                                        gallery={gallery}
                                        imgHeight={imgHeight}
                                    />)}
        </div>
    );
}
