import { ComponentClass, lazy } from "react";
import FooterTraveltool from "../components/alexandria/desktop/footer-traveltool/footer-traveltool";
import Products from "../components/products/products";

const desktopComponents = {
  "footer-traveltool": FooterTraveltool,
  "header-traveltool": lazy(
    () =>
      import(
        "../components/alexandria/desktop/header-traveltool/header-traveltool"
      )
  ),
  products: Products,
};

export default class ProductsComponentMapper {
  static getMappedComponent(componentName: string, environment: string) {
    return desktopComponents[componentName];
  }
}
