import React, { useState } from "react";

export default function Button() {
  const [clickedButton, setClickedButton] = useState('');

  const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const button: HTMLButtonElement = event.currentTarget;
    setClickedButton(button.name);
    alert("testing");
  };
  return (
    <button type="button" onClick={buttonHandler}>
      Click me!
    </button>
  );
}