import { EventProps } from "./props/event.props";
import "./event.css";
import EventBase from "./event-base";

export default function Event(props: Readonly<EventProps>) {
  const {
    address,
    flight,
    description,
    icon,
    googleRatingsLiteral,
    imageUrls,
    internationalPhone,
    place,
    roomType,
    regime,
    nights,
    rating,
    ship,
    title,
    totalRatings,
    url,
    website,
    optional,
    startDate,
    startTime,
    endDate,
    endTime,
    id,
  } = props;
  return (
    <div className="event-base" data-testid="event-base">
      <EventBase
        optional={optional}
        address={address}
        googleRatingsLiteral={googleRatingsLiteral}
        icon={icon}
        imageUrls={imageUrls}
        internationalPhone={internationalPhone}
        place={place}
        roomType={roomType}
        regime={regime}
        nights={nights}
        rating={rating}
        totalRatings={totalRatings}
        url={url}
        website={website}
        flight={flight}
        ship={ship}
        description={description}
        title={title}
        id={id}
        key={id}
        startDate={startDate}
        endDate={endDate}
        startTime={startTime}
        endTime={endTime}
      />
    </div>
  );
}
