import { StyleSheet } from "@react-pdf/renderer";


const style = StyleSheet.create({
  body: {
    paddingVertical: 55,
    paddingHorizontal: 55,
    fontSize: 12,
    display: "flex",
    gap: 10,
  },
});

export default style;
