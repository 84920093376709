import { LeadForm } from "../../../props/side-menu.props";
import { createPortal } from "react-dom";
import InterestedModal from "../../interested-modal/interested-modal";
import useModalState from "../../custom-hooks/use-modal-state";

export default function InterestedButton(leadForm: Readonly<LeadForm>) {
  const [showModal, { createModal, closeModal }] = useModalState();
  return (
    <>
      <button
        data-testid="interested-button"
        onClick={createModal}
        className="button pdf-button"
        type="button"
      >
        {leadForm.literals["ImInterested"]}
      </button>
      {showModal &&
        createPortal(
          <InterestedModal onClose={() => closeModal()} leadForm={leadForm} />,
          document.body
        )}
    </>
  );
}
