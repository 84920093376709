import React from "react";
import PropTypes, { InferProps } from "prop-types";

type CardProps = {
    children: JSX.Element|JSX.Element[];
  };


export default function Card({ ...props }: CardProps) {
  return (
    <section>
      <div>This is a card</div>
      {props.children}
    </section>
  );
}
