import axios from "axios";
import ProductsResponse from "../components/products/dto/response/ProductsBaseResponse";

export default class ProductsService {

  static async getProductsByPage(
    page: number
  ): Promise<ProductsResponse | undefined> {
    try {
      const rs = await axios.get<any>(`/get-products/${page}`);
      return new ProductsResponse(rs.data);
    } catch (error){
      return;
    }
  }
}