import { BrowserRouter } from 'react-router-dom';
import React, { lazy } from 'react';
import { hydrateRoot } from 'react-dom/client';
import r2wc from '@r2wc/react-to-web-component';
import { hydrate } from 'react-dom';
import App from './client/App';
import PageContext from './client/context';
import PageWithModels from './models/page-with-models';
import SimpleComponent from './client/SimpleComponent';
import ComponentModelRs from './dto/component/response/component-model-rs';
import ComponentInstance from './models/component-instance';
import TestContext from './client/test-context';

const initialData = (window as any).INITIAL_STATE as PageWithModels;
const testComponentName = (window as any).TEST_COMPONENT_NAME as string;
const simpleComponentContainer = (window as any)
  .SIMPLE_COMPONENT_CONTAINER as string;
const componentInstance = (window as any)
  .COMPONENT_INSTANCE as ComponentInstance;
const environment = (window as any).ENVIRONMENT as string;
const models = (window as any).COMPONENT_MODELS as ComponentModelRs[];

if (simpleComponentContainer) {
  hydrateRoot(
    document.getElementById(simpleComponentContainer) as HTMLElement,
    <SimpleComponent
      name="header"
      componentInstance={componentInstance}
      models={models}
      environment={environment}
    />
  );
} else {
  const container = document.getElementById("root") as HTMLElement;
  if (container) {
    hydrate(
      <PageContext.Provider value={initialData}>
        <TestContext.Provider value={testComponentName}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </TestContext.Provider>
      </PageContext.Provider>,
      container
      );
    }
  }

const header = r2wc(
  lazy(() => import("./components/header/header")),
  {
    props: {
      props: "json",
    },
});

const mobileHeader = r2wc(
  lazy(() => import("./components/mobile-header/header")),
  {
    props: {
      props: "json",
    },
  }
);

customElements.define("b2c-header", header);
customElements.define("b2c-mobile-header", mobileHeader);
  
if (module.hot) {
  module.hot.accept();
}
