import { NotIncludedServicesProps } from "./props/not-included-services.props";
import  MoguText  from "../text/text";
import "./not-included-services.css"

export default function IncludedServices({ Text, Title }: Readonly<NotIncludedServicesProps>) {
    return (
        <div className="not-included-services" data-testid="not-included-services">
            <div className="not-included-services-title">
                <h4>{Title}</h4>
            </div>
            <MoguText Text={Text}></MoguText>
        </div>
    );
}
