import { StyleSheet } from "@react-pdf/renderer";

const style = StyleSheet.create({
  container: {
    marginTop: 30
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'space-between',
    color: '#1f2e5c'
  },
  title: {
    flex: '1 1 0%',
  }
});

export default style;
