import React, { ReactElement } from 'react';
import ComponentModelRs from '../dto/component/response/component-model-rs';
import ComponentInstance from '../models/component-instance';
import getComponentModel from './base-renderer';
import ProductComponentMapper from '../mappers/product-component.mapper';
import MobileProductComponentMapper from '../mappers/mobile-product-component.mapper';

type ProductNoLazyRendererProps = {
  componentInstance: ComponentInstance;
  models: ComponentModelRs[],
  environment: string
};

function ProductNoLazyRenderer(
  {...props}: ProductNoLazyRendererProps
): ReactElement {
  const { componentInstance, models, environment } = props;
  const { component } = componentInstance;

  const desktopToMobileComponents = {
    "header-traveltool": "m-header-traveltool",
    "footer-traveltool": "m-footer-traveltool"
  };

  const resolvedComponent = component.isMobile
    ? MobileProductComponentMapper.getMappedComponent(
        desktopToMobileComponents[component.code],
        environment
      )
    : ProductComponentMapper.getMappedComponent(component.code, environment);
  if (resolvedComponent) {
    return React.createElement(
      resolvedComponent,
      getComponentModel(component, models, component.isMobile, environment)
    );
  }
  return React.createElement("div", {});
}
export default ProductNoLazyRenderer;
