import { StyleSheet } from "@react-pdf/renderer";

const style = StyleSheet.create({
  container: {
    marginTop: 20,
    padding: 10,
    backgroundColor: "#F8FAFC",
    borderRadius: 6,
    display: "flex",
    flexDirection: "row",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 5,
    paddingHorizontal: 12,
    marginVertical: 8,
    borderRight: "2px solid #e2e8f0",
    flexGrow: 1,
  },
  logo: {
    width: 46,
    height: 46,
    borderRadius: 6,
  },
  flightNumber: {
    backgroundColor: "#ffffff",
    border: "1px solid #e2e8f0",
    borderRadius: 6,
    padding: "4px 10px",
    marginTop: 5,
  },
  flightContentContainer: {
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: 12,
    marginVertical: 8,
    flexGrow: 3,
    justifyContent: "space-around",
  },
  middleContentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 5,
  },
});

export default style;
