import { ImagesModalProps } from "./props/images-modal.prop";
import "./images-modal.css";
import { useState } from "react";
import ImageUrlProxyUtils from "../../../../utils/imageUrlProxy.utils";

export default function ImagesModal({
  images,
  onClose,
  selectedImageIndex,
}: Readonly<ImagesModalProps>) {
  const [currentImageIndex, setCurrentImageIndex] =
    useState(selectedImageIndex);

  const clickRight = () => {
    if (images.length === 1) return;

    const nextIndex =
      currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1;

    setCurrentImageIndex(nextIndex);
  };

  const clickLeft = () => {
    if (images.length === 1) return;

    const nextIndex =
      currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1;

    setCurrentImageIndex(nextIndex);
  };

  return (
    <div data-testid="images-modal" className="modal-photo-container">
      <div className="modal-photo-container__body">
        <div className="modal-photo-container__top">
          <button
            onClick={onClose}
            onKeyDown={onClose}
            className="modal-photo-container__close"
          >
            <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
            </svg>
          </button>
        </div>
        <div className="modal-photo-container__slider-item">
          <button
            onClick={clickLeft}
            onKeyDown={clickLeft}
            className="modal-photo-container__arrow"
          >
            <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z"></path>
            </svg>
          </button>
          <div className="modal-photo-container__image-container disable-select">
            <div className="modal-photo-container__image-zoom-container">
              <div className="image-container">
                <img
                  className="modal-photo-container__image"
                  src={ImageUrlProxyUtils.processImageUrl(images[currentImageIndex]?.image_url)}
                  alt=""
                ></img>
              </div>
            </div>
          </div>
          <button
            onClick={clickRight}
            onKeyDown={clickRight}
            className="modal-photo-container__arrow"
          >
            <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
