import { HeaderBasicsLiteralsProps } from "../props/header-basics-literals.props";

const buildDateWithDays = (
  numDays: number,
  startDateString: string,
  endDateString: string,
  literals: HeaderBasicsLiteralsProps
): string => {
  const startDateFormatted = buildDate(startDateString);
  const endDateFormatted = buildDate(endDateString);

  if (startDateFormatted === "" || endDateFormatted === "") return "";
  let daysLiteral = numDays > 1 ? literals?.DaysLiteral : literals?.DayLiteral;
  if (daysLiteral === undefined) {
    daysLiteral = "días";
  }
  return `${numDays} ${daysLiteral} | ${startDateFormatted} - ${endDateFormatted}`;
};

const isNullOrEmpty = (str: string) => str === null || str.trim().length === 0;

const formatDate = (date: Date) =>
  date
    .toLocaleDateString("es-ES", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    })
    .slice(0, -3);

const buildDate = (dateString: string): string => {
  if (isNullOrEmpty(dateString)) return "";
  const date = new Date(dateString);
  if (isNaN(date.valueOf())) return "";
  return formatDate(date);
};

export { buildDateWithDays, buildDate };
