import { ReactElement } from "react";
import { GooglePlace } from "./../itinerary/props/itinerary.props";
import "./google-widget-place.css";
import { GoogleWidgetPlaceProps } from "./props/google-widget-place.props";

export default function GoogleWidgetPlace({
  googlePlace,
}: Readonly<GoogleWidgetPlaceProps>) {
  return (
    <div data-testid="google-widget-place" className="event-google-widget-container">
      <div className="event-google-widget-box">
        {googlePlace?.rating > 0 && BuildEventPlace(googlePlace)}
        {BuildWeb(googlePlace)}
        {BuildLocation(googlePlace)}
        {BuildPhone(googlePlace)}
      </div>
    </div>
  );
}

const BuildEventPlace = (googlePlace: GooglePlace) => {
  return (
    <div className="event-google-widget-rating-container">
      <div className="event-place-rating">
        <div className="event-place-rating-number">{googlePlace.rating}</div>
        {BuildRatingStars(googlePlace)}
      </div>
      {googlePlace.url && (
        <a href={googlePlace.url} target="_blank" rel="noopener noreferrer">
          <div className="event-google-widget-total-rating">
            {googlePlace.user_ratings_total}{" "}
            {googlePlace.ratingsLiteral ?? `google reviews`}
          </div>
        </a>
      )}
    </div>
  );
};

const BuildWeb = (googlePlace: GooglePlace) => {
  if (
    !googlePlace ||
    googlePlace.website === undefined ||
    googlePlace.website === null ||
    googlePlace.website === ""
  )
    return;

  return (
    <div className="event-google-widget-rating-container">
      <a
        href={googlePlace.website}
        target="_blank"
        rel="noopener noreferrer"
        className="event-google-widget__link"
      >
        <div className="event-place-information-container">
          <div className="event-place-information-icon">
            <svg
              className="event-star-icon"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M6.235 6.453a8 8 0 0 0 8.817 12.944c.115-.75-.137-1.47-.24-1.722-.23-.56-.988-1.517-2.253-2.844-.338-.355-.316-.628-.195-1.437l.013-.091c.082-.554.22-.882 2.085-1.178.948-.15 1.197.228 1.542.753l.116.172c.328.48.571.59.938.756.165.075.37.17.645.325.652.373.652.794.652 1.716v.105c0 .391-.038.735-.098 1.034a8.002 8.002 0 0 0-3.105-12.341c-.553.373-1.312.902-1.577 1.265-.135.185-.327 1.132-.95 1.21-.162.02-.381.006-.613-.009-.622-.04-1.472-.095-1.744.644-.173.468-.203 1.74.356 2.4.09.105.107.3.046.519-.08.287-.241.462-.292.498-.096-.056-.288-.279-.419-.43-.313-.365-.705-.82-1.211-.96-.184-.051-.386-.093-.583-.135-.549-.115-1.17-.246-1.315-.554-.106-.226-.105-.537-.105-.865 0-.417 0-.888-.204-1.345a1.276 1.276 0 0 0-.306-.43zM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z"></path>
            </svg>
          </div>
          <div className="event-place-information-text event-web">
            {googlePlace.website}
          </div>
        </div>
      </a>
    </div>
  );
};

const BuildLocation = (googlePlace: GooglePlace) => {
  if (
    !googlePlace ||
    googlePlace.formatted_address === undefined ||
    googlePlace.formatted_address === null ||
    googlePlace.formatted_address === ""
  )
    return;

  return (
    <div className="event-google-widget-rating-container">
      <div className="event-place-information-container">
        <div className="event-place-information-icon">
          <svg
            className="event-star-icon"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"></path>
          </svg>
        </div>
        <div className="event-place-information-text event-web">
          {googlePlace.formatted_address}
        </div>
      </div>
    </div>
  );
};

const BuildPhone = (googlePlace: GooglePlace) => {
  if (
    !googlePlace ||
    googlePlace.international_phone_number === undefined ||
    googlePlace.international_phone_number === null ||
    googlePlace.international_phone_number === ""
  )
    return;

  return (
    <div className="event-google-widget-rating-container">
      <div className="event-place-information-container">
        <div className="event-place-information-icon">
          <svg
            className="event-star-icon"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M21 16.42v3.536a1 1 0 0 1-.93.998c-.437.03-.794.046-1.07.046-8.837 0-16-7.163-16-16 0-.276.015-.633.046-1.07A1 1 0 0 1 4.044 3H7.58a.5.5 0 0 1 .498.45c.023.23.044.413.064.552A13.901 13.901 0 0 0 9.35 8.003c.095.2.033.439-.147.567l-2.158 1.542a13.047 13.047 0 0 0 6.844 6.844l1.54-2.154a.462.462 0 0 1 .573-.149 13.901 13.901 0 0 0 4 1.205c.139.02.322.042.55.064a.5.5 0 0 1 .449.498z"></path>
          </svg>
        </div>
        <div className="event-place-information-text">
          {googlePlace.international_phone_number}
        </div>
      </div>
    </div>
  );
};

const BuildRatingStars = (googlePlace: GooglePlace) => {
  if (
    !googlePlace ||
    googlePlace.rating === undefined ||
    googlePlace.rating === null
  )
    return;

  const elements: ReactElement[] = [];

  for (let index = 0; index < 5; index++) {
    elements[index] = BuildRatingStar(
      index + 1 <= googlePlace.rating,
      index,
      googlePlace.name
    );
  }

  return <div className="event-place-rating-stars">{elements}</div>;
};

const BuildRatingStar = (active: boolean, index: number, placeName: string) => {
  const className = active ? "event-star event-star-color" : "event-star";

  return (
    <div key={`starKey-${placeName}-${index}`} className={className}>
      <svg
        className="event-star-icon"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928z"></path>
      </svg>
    </div>
  );
};
