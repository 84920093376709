import DOMPurify from "isomorphic-dompurify";
import "./video.css";
import { VideoProps } from "./props/video.props";

export default function Video({ Iframe }: Readonly<VideoProps>) {
  const allowedTags = {
    ALLOWED_TAGS: ["iframe"],
  };

  return (
    <div
      className="video-container"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(Iframe, allowedTags),
      }}
    ></div>
  );
}
