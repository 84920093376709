import { StyleSheet } from "@react-pdf/renderer";

const style = StyleSheet.create({
  itinerariesContainer: {
    marginTop: 30,
  },
  itineraryContainer: {
    paddingVertical: 15,
  },
  itineraryTitle: {
    color: "#1f2e5c",
  },
  itineraryImage: {
    maxHeight: 300,
    borderRadius: 10,
    backgroundColor: "#e6e9ed",
    marginTop: 20,
  },
});

export default style;
