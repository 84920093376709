import { StyleSheet } from "@react-pdf/renderer";

const style = StyleSheet.create({
  container: {
    marginBottom: 30,
    display: "flex",
    gap: 5,
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    color: "#667b99",
  },
  iconTitle: {
    width: 14,
    height: 14,
    marginRight: 5,
  },
  shipContainer: {
    marginTop: 10,
    fontSize: 10,
    color: "#1F2E5C",
  },
  shipImage: {
    maxHeight: 300,
    borderRadius: 10,
    backgroundColor: "#e6e9ed",
    marginTop: 20,
  },
});

export default style;
