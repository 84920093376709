import { createPortal } from "react-dom";
import SendMailModal from "./send-mail-modal/send-mail-modal";
import useModalState from "../../custom-hooks/use-modal-state";
import { SendMailButtonProps } from "./props/send-mail-button.props";

export default function SendMailButton({
  productName,
  literals,
  token
}: Readonly<SendMailButtonProps>) {
  const [showModal, { createModal, closeModal }] = useModalState();
  return (
    <>
      <button
        onClick={createModal}
        onKeyDown={createModal}
        className="icon-button"
        data-testid="send-mail-button"
      >
        <svg
          className="remixicon-icon "
          width="24"
          height="24"
          fill="currentColor"
          viewBox="-1 0 24 24"
        >
          <g>
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M22 20.007a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V19h18V7.3l-8 7.2-10-9V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v16.007zM4.434 5L12 11.81 19.566 5H4.434zM0 15h8v2H0v-2zm0-5h5v2H0v-2z" />
          </g>
        </svg>
      </button>
      {showModal &&
        createPortal(
          <SendMailModal
            onClose={() => closeModal()}
            productName={productName}
            literals={literals}
            token={token}
          />,
          document.body
        )}
    </>
  );
}
