import { useContext, useEffect } from "react";
import PageContext from "../../client/context";
import { injectStyle } from "react-toastify/dist/inject-style";
import "./own-product-carousel.css";
import ProductCard from "../products/subcomponents/product-card/product-card";
import OwnProductCarouselResponse from "./dto/response/ProductsBaseResponse";
import Slider from "react-slick";
import { SlickProps } from "./props/slick-props";
const MOGU_PROPOSALS_COMPONENT = "own-product-carousel";
const SlickArrowLeft = (props: SlickProps) => (
  <div
    {...props}
    className={
      "custom-arrow__navigation custom-arrow__navigation--previous " +
      (props.currentSlide === 0 ? " custom-arrow-disabled" : "")
    }
  >
    <i className="nico-angle-left"></i>
  </div>
);
const SlickArrowRight = (props: SlickProps) => (
  

  <div
    {...props}
    className={
      "custom-arrow__navigation custom-arrow__navigation--next" +
      (props.currentSlide === 7 ? " custom-arrow-disabled" : "")
    }
  >
    <i className="nico-angle-right"></i>
  </div>
); 

const settings = {
  arrows: true,
  dots: true,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 4,
  prevArrow: <SlickArrowLeft currentSlide={0} slideCount={0} />,
  nextArrow: <SlickArrowRight currentSlide={0} slideCount={0}  />,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: true
      }
    },{
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export default function OwnProductCarousel() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      injectStyle();
    }
  }, []);
  console.log("creando")
  let context = useContext(PageContext);
  let model = context?.models?.find((model) => model.componentCode === MOGU_PROPOSALS_COMPONENT);
  let data =model?.model;
  let parsedComponent = JSON.parse(data as unknown as string);
  let rs:OwnProductCarouselResponse = new OwnProductCarouselResponse(parsedComponent.data);
  return (
    <div data-testid="own-product-carousel" className="container">
        <div className="title" data-component="Title">
          <div className="title__icon">
            <i className="nico-suitcase"></i>
          </div>
          <h3 className="title__title">{rs.title}</h3>
        </div>
        <div >
          <Slider {...settings} >
            {rs.parsedProductsData.map((product) => {
              return (<ProductCard key={product.Slug} {...product}/>);
            })}
            <div className="product-card">
              <a href="/productos" className="product-link"></a>
              <div className="content">
              <i className="nico-box-plus"></i>
              <p className="show-more-products">Ver más ofertas</p>
              </div>  
            </div>
          </Slider>
      </div>
    </div>
  );
}