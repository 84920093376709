import axios from "axios";
import GeolocationResponse from "../models/api/smytravel-api/geolocation-response";

export default class GeolocationService {
  static async getOriginAirportByCoordinates(
    latitude: number,
    longitude: number,
    language: string
  ): Promise<GeolocationResponse> {
    const rs = await axios.get<GeolocationResponse>(
      `/geolocation/${latitude}/${longitude}?language=${language}`,
      {
        withCredentials: false,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET",
        },
      }
    );

    return rs.data;
  }
}
