import { Helmet } from "react-helmet";
import GoogleWidgetPlace from "../google-widget-place/google-widget-place";
import { buildDate } from "../header-basics/utils/date-builder";
import MoguText from "../text/text";
import FlightCard from "./flight-card/flight-card";
import { EventProps } from "./props/event.props";
import "./event.css";
import EventGalleryBlock from "./event-gallery-block/event-gallery-block";

export default function EventBase(props: Readonly<EventProps>) {
  const {
    address,
    flight,
    description,
    icon,
    googleRatingsLiteral,
    imageUrls,
    internationalPhone,
    place,
    roomType,
    regime,
    nights,
    rating,
    ship,
    title,
    totalRatings,
    url,
    website,
    optional,
    startDate,
    startTime,
    endDate,
    endTime,
  } = props;

  const hasGooglePlace = (): boolean =>
    address != null ||
    internationalPhone != null ||
    rating != null ||
    url != null ||
    totalRatings != null ||
    website != null;

  const hasEventInfo = (): boolean =>
    hasGooglePlace() ||
    ship != null ||
    place != null ||
    imageUrls?.length > 0 ||
    description != null ||
    flight != null;

  return (
    <>
      <Helmet>
        <link
          href="https://cdn.jsdelivr.net/npm/remixicon@3.4.0/fonts/remixicon.css"
          rel="stylesheet"
        />
      </Helmet>
      <div className="event-grid event-title">
        <div className="icon-box">
          <div className="icon">
            <i className={icon} />
          </div>
        </div>
        <div className="event-title-base">
          {buildTitle(optional, title, startDate, endDate, startTime, endTime)}
        </div>
      </div>
      {hasEventInfo() && (
        <div className="event-info">
          {ship && (
            <div className="ship-name">
              <div className="ship-name-container">
                <span>{ship.operatedByLiteral}</span>
                <span>
                  {ship.shipLiteral}&nbsp;
                  <strong>{ship.name}</strong>
                </span>
              </div>
            </div>
          )}
          {place && <div className="place">{place}</div>}
          <div className="room-info" data-testid="room-info">
            {roomType && <div className="roomType" data-testid="roomType"><i className="ri-user-add-line"></i>{roomType}</div>}
            {regime && <div className="regime" data-testid="regime"><i className="ri-equalizer-line"></i>{regime}</div>}
            {nights && <div className="nights" data-testid="nights"><i className="ri-moon-line"></i>{nights}</div>}
          </div>
          {imageUrls?.length > 0 && 
            <EventGalleryBlock imageUrls={imageUrls}/>
          }

          {description && (
            <MoguText
              Text={description}
              style={{
                fontSize: 16,
                lineHeight: "calc(24px)",
                marginBottom: 20,
                overflowX: "auto",
              }}
            />
          )}
          {ship && (
            <div className="ship-image">
              <div className="proposal-images">
                <div
                  className="proposal-images__image proposal-images__main full-width"
                  style={{
                    backgroundImage: `url(${ship.image})`,
                    width: "100%",
                  }}
                />
              </div>
            </div>
          )}
          {hasGooglePlace() && (
            <GoogleWidgetPlace
              googlePlace={{
                formatted_address: address,
                international_phone_number: internationalPhone,
                name: description,
                photos: [],
                rating,
                ratingsLiteral: googleRatingsLiteral,
                url,
                user_ratings_total: totalRatings,
                website,
              }}
            />
          )}
          {flight && (
            <FlightCard
              airlineLogo={flight?.airlineLogo}
              airlineName={flight?.airlineName}
              arrival={flight?.arrival}
              departure={flight.departure}
              flightNumber={flight.flightNumber}
              flightTime={flight.flightTime}
            />
          )}
        </div>
      )}
    </>
  );
}

const buildTitle = (
  optional: boolean,
  title: string,
  startDate?: string,
  endDate?: string,
  startTime?: string,
  endTime?: string
) => {
  const optionalString = optional ? "(Optional) " : "";
  const titleString = `${optionalString}${title}`;

  if (!startDate && !startTime) return titleString;

  if ((!startDate || !endDate) && startTime && endTime)
    return `${startTime} - ${endTime} ${titleString}`;

  if (startDate && endDate) {
    const startDateFormatted = buildDate(startDate);
    const endDateFormatted = buildDate(endDate);

    if (startTime && endTime)
      return `${startDateFormatted} · ${startTime} - ${endDateFormatted} · ${endTime} ${titleString}`;

    return `${startDateFormatted} - ${endDateFormatted} ${titleString}`;
  }
  return titleString;
};
