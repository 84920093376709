import { InterestedModalProps } from "./props/interested-modal.props";
import "./interested-modal.css";
import { ReactElement, useState } from "react";
import QuestionItem from "./question-item/question-item";

export default function InterestedModal({
  onClose,
  leadForm,
}: Readonly<InterestedModalProps>) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [choices, setChoices] = useState<string[]>([]);

  const updateInput = (type: string, value: any) => {
    if (type === "name") setName(value);
    if (type === "email") setEmail(value);
    if (type === "message") setMessage(value);
    if (type === "multiple-choices") {
      value as string;
      const isRemove = choices.includes(value);
      setChoices((x) =>
        isRemove ? [...x.filter((x) => x !== value)] : [...x, value]
      );
    }
  };

  const { questions } = leadForm;

  const sendForm = () => {
    console.log({ name, email, message, choices });
  };

  const questionElements: ReactElement[] = [];
  questions.forEach((x) => {
    questionElements.push(
      <QuestionItem callBack={updateInput} key={x.id} question={x} />
    );
  });

  return (
    <div data-testid="interested-modal" className="i-modal-container visible centered">
      <div className="i-modal medium">
        <div className="i-modal-header">
          <button
            onClick={onClose}
            className="modal-button transparent i-modal-close-button"
            type="button"
          >
            <div className="button__inner">
              <svg
                className="remixicon-icon "
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
              </svg>
            </div>
          </button>
          <div className="content">{leadForm.literals["ImInterested"]}</div>
        </div>
        <div className="i-modal-body">
          <div className="i-modal-body-inner">
            <div className="i-modal-body-inner-box">{questionElements}</div>
          </div>
        </div>
        <div className="i-modal-footer">
          <button
            onClick={onClose}
            onKeyDown={onClose}
            type="button"
            className="modal-button transparent"
          >
            <div className="button__inner">{leadForm.literals["Cancel"]}</div>
          </button>
          <button
            onClick={sendForm}
            onKeyDown={sendForm}
            type="button"
            className="modal-button primary"
          >
            <div className="button__inner">{leadForm.literals["Send"]}</div>
          </button>
        </div>
      </div>
    </div>
  );
}
