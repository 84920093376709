import { View } from "@react-pdf/renderer";

type PdfSpacerProps = {
  Content?: any
}

export default function PdfSpacer({ Content }: Readonly<PdfSpacerProps>) {
  return (
    <View break>
    </View>
  );
}
