import { Choice } from "../../../../props/side-menu.props";
import QuestionChoice from "./question-choice";

type QuestionMultipleChoicesProps = {
  label: string;
  choices: Choice[] | undefined;
  callBack: (type: string, value: string) => void;
};

export default function QuestionMultipleChoices({
  label,
  choices,
  callBack
}: Readonly<QuestionMultipleChoicesProps>) {
  return (
    <>
      <div className="stripe__form-question-label">
        <label className="question-label">{label}</label>
        <div className="form-question__required-indicator">*</div>
      </div>
      {choices?.map((x) => (
        <QuestionChoice callBack={callBack} key={x.id} id={x.id} label={x.label} />
      ))}
    </>
  );
}
