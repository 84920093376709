import { View } from "@react-pdf/renderer";
import style from "./pdf-divider.style";

export default function PdfDivider() {
  return (
    <View style={style.container}>
      
    </View>
  );
}
