import { ProductInfoProps } from "../../../products/subcomponents/product-card/props/product-card.props";

export default class OwnProductCarouselResponse {
    public readonly parsedModel:any;
    public readonly title:string;
    public readonly parsedProductsData: ProductInfoProps[];

    public constructor(data: any) {
        this.parsedModel = JSON.parse(data);
        this.title = this.parsedModel.Title;
        this.parsedProductsData = this.parsedModel.Products
      }
}