import { StyleSheet } from "@react-pdf/renderer";

const style = StyleSheet.create({
  container: {
    marginBottom: 15,
    marginTop: 20,
    display: "flex",
    alignItems: "center",
  },
  image: {
    width: "30%",
  },
});

export default style;
