
export type ProductInfoProps = {
    ImageUrl: string,
    Title:string,
    StartDate: string,
    EndDate: string,
    NumDays: number,
    Description: string,
    ProposalPrice: string,
    Slug:string
};

export enum CardPriceProposal {
    to,
    from
}