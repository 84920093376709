import React, { ReactElement } from 'react';
import ComponentMapper from '../mappers/component.mapper';

function getComponentModel(componentName: string): {
    id: string;
    html: string;
    props?: any;
} {
  
  return {} as {
    id: string;
    html: string;
    props?: any;
  };
}
function TestRenderer(
  componentName: string
): ReactElement {
  const resolvedComponent = ComponentMapper.getMappedComponent(
    componentName,
    "production"
  );
  if (resolvedComponent) {
    return React.createElement(resolvedComponent, getComponentModel(componentName));
  }
  return React.createElement("div", {});
}
export default TestRenderer;
