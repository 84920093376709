import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import "../styles/App.css";
import Home from "./Home";
import ErrorPage from "./404";
import TestComponent from "./TestComponent";
import TestContext from "./test-context";
import Product from "./Product";
import Products from "./Products";

function App() {
  const testComponentName = useContext(TestContext);
  return (
    <HelmetProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/page/*" element={<Home />} />
        <Route path="/404" element={<ErrorPage />} />
        <Route
          path="/test-component/*"
          element={<TestComponent name={testComponentName as string} />}
        />
        <Route path="/producto/:token/expired" element={<Product expired={true}/>}/>
        <Route path="/producto/:token" element={<Product/>}/>
        <Route path="/productos" element={<Products/>}/>
      </Routes>
    </HelmetProvider>
  );
}

export default App;
