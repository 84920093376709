import { useState } from "react";
import { Image } from "../props/itinerary.props";
import "../itinerary.css";
import MoguText from "../../text/text";
import ProposalImages from "../../proposal-images/proposal-images";
import { ItineraryElementProps } from "./props/itinerary-element.props";
import { EventProps } from "../../event/props/event.props";
import EventBase from "../../event/event-base";

export default function ItineraryElement({
  day,
  defaultExpanded: defaultState
}: Readonly<ItineraryElementProps>) {
  const [expanded, setExpanded] = useState(defaultState);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const displayStyle = {
    display: expanded ? "block" : "none",
  };

  return (
    <div data-testid="itinerary-element" className="itinerary-element">
      <button
        type="button"
        onClick={toggleExpanded}
        onKeyDown={toggleExpanded}
        className="itinerary-title-container"
      >
        <div className="itinerary-title">{day.title}</div>
        <svg
          className={
            expanded ? "itinerary-title-icon-expanded" : "itinerary-title-icon"
          }
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"></path>
        </svg>
      </button>
      <div className="itinerary-body" style={displayStyle}>
        <MoguText Text={day.description} />
        {buildImages(day.images)}
        {buildEvents(day.events)}
      </div>
    </div>
  );
}

const buildImages = (images: Image[]) => {
  if (!images || images.length === 0) return;

  return (
    <div className="itinerary-images-container">
      <ProposalImages images={images} />
    </div>
  );
};

const buildEvents = (events: EventProps[]) => {
  if (!events || events.length === 0) return;

  return (
    <div className="events-container">
      {events.map((x) => (
        <EventBase
          optional={x.optional}
          address={x.address}
          googleRatingsLiteral={x.googleRatingsLiteral}
          icon={x.icon}
          imageUrls={x.imageUrls}
          internationalPhone={x.internationalPhone}
          place={x.place}
          nights={x.nights}
          roomType={x.roomType}
          regime={x.regime}
          rating={x.rating}
          totalRatings={x.totalRatings}
          url={x.url}
          website={x.website}
          flight={x.flight}
          ship={x.ship}
          description={x.description}
          title={x.title}
          id={x.id}
          key={x.id}
        />
      ))}
    </div>
  );
};
