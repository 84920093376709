import { ProductInfoProps } from "../../subcomponents/product-card/props/product-card.props";

export default class ProductsResponse {
  public title: string;
  public productsData: ProductInfoProps[];
  public totalProducts: number;

  public constructor(data: any) {
    const parsedModel = typeof data == "string" ? JSON.parse(data) : data;
    this.title = parsedModel.Title;
    this.productsData = parsedModel.Products;
    this.totalProducts = parsedModel.Total;
  }
}