import { lazy } from "react";
import FooterTraveltool from "../components/alexandria/desktop/footer-traveltool/footer-traveltool";
import Product from "../components/product/product";

const desktopComponents = {
  "footer-traveltool": FooterTraveltool,
  "header-traveltool": lazy(
    () =>
      import(
        "../components/alexandria/desktop/header-traveltool/header-traveltool"
      )
  ),
  product: Product,
};

export default class ProductComponentMapper {
  static getMappedComponent(componentName: string, environment: string) {
    return desktopComponents[componentName];
  }
}
