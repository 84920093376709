/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";
import DOMPurify from "isomorphic-dompurify";
import "./scss/main.scss";
import MFooterTraveltool from "@logitravel/alex-mobile-smytravel/components/m-footer-traveltool/client/js";

type HeaderTraveltoolProps = {
  html: string;
};

export default function MFooterTraveltoolComponent({ ...props }: HeaderTraveltoolProps) {
  useEffect(() => {
    if (props.html) {
      try {
        MFooterTraveltool('[data-component="MFooterTraveltool"]');
      } catch (error) {
        console.log('Error loading component client JS');
      }
    }
  }, []);
  return (
    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.html) }} />
  );
}
