type QuestionChoiceProps = {
  id: string;
  label: string;
  callBack: (type: string, value: string) => void;
};

export default function QuestionChoice({
  id,
  label,
  callBack,
}: Readonly<QuestionChoiceProps>) {
  return (
    <div className="stripe__form-question-choice">
      <div className="mogu-checkbox question-choice__input-radio">
        <div className="inner">
          <input
            onChange={(e) => callBack("multiple-choices", label)}
            name={id}
            type="checkbox"
          />
        </div>
      </div>
      <div className="stripe__form-question-choice__label">{label}</div>
    </div>
  );
}
