import { useEffect } from "react";
import "./product.css";
import { injectStyle } from "react-toastify/dist/inject-style";

type ErrorProps = {
    errorImage?: any;
    errorText: string;
  };

export default function ProductError({ ...props }: Readonly<ErrorProps>) {
    useEffect(() => {
        if (typeof window !== "undefined") {
            injectStyle();
        }
    }, []);
    return (
        <div className="error-container" data-testid="product-error-message">
            <img className="error-image" alt="error image" src={props.errorImage} />
            <p className="error-text">{props.errorText}</p>
        </div>
    );
}
