import { Text, View } from "@react-pdf/renderer";
import { buildDateWithDays } from "../../../header-basics/utils/date-builder";
import style from "./pdf-header-basics.style";
import { PdfHeaderBasicsProps } from "./props/pdf-header-basics.props";

export default function PdfHeaderBasics({
  Title,
  NumDays,
  StartDate,
  EndDate,
  literals,
  pdfStyles
}: Readonly<PdfHeaderBasicsProps>) {
  return (
    <View style={style.container}>
      <Text style={{ ...style.title, color: pdfStyles.secondaryColor600 ?? "#000000" }}>
        {Title}
      </Text>
      <Text style={{ ...style.date, color: pdfStyles.primaryColor700 ?? "#000000" }}>
        {buildDateWithDays(NumDays, StartDate, EndDate, literals)}
      </Text>
    </View>
  );
}
