import { StyleSheet } from "@react-pdf/renderer";

const style = StyleSheet.create({
  title: {
    fontSize: 18,
    color: "#150A44",
  },
});

export default style;
