import React, { ReactElement } from 'react';
import ComponentModelRs from '../dto/component/response/component-model-rs';
import ComponentInstance from '../models/component-instance';
import ComponentMapper from '../mappers/component.mapper';
import ComponentInstanceSlot from '../models/component-slot';
import getComponentModel from './base-renderer';
import MobileComponentMapper from '../mappers/mobile.component.mapper';

function getComponentSlots(
  componentInstanceParentSlots: ComponentInstanceSlot[],
  models: ComponentModelRs[],
  environment: string
): ReactElement[] {
  if (componentInstanceParentSlots) {
    return componentInstanceParentSlots.map(
      (componentInstanceSlot: ComponentInstanceSlot) => {
        const { child } = componentInstanceSlot;
        const { component: childComponent } = child;
        const childResolvedComponent = ComponentMapper.getMappedComponent(
          childComponent.code,
          environment
        );
        return React.createElement(
          childResolvedComponent,
          getComponentModel(childComponent, models, childComponent.isMobile, environment),
          getComponentSlots(child.componentInstanceParentSlots, models, environment)
        );
      }
    )
  } 
  return  [ React.createElement("div", {}) ];
}

type DynamicRendererProps = {
  componentInstance: ComponentInstance;
  models: ComponentModelRs[],
  environment: string
};

function DynamicRenderer(
  {...props}: DynamicRendererProps
): ReactElement {
  const { componentInstance, models } = props;
  const { component, componentInstanceParentSlots } = componentInstance;

  const desktopToMobileComponents = {
    "header-carousel": "m-banner-carousel",
    "header-traveltool": "m-header-traveltool",
    "multisearcher": "m-tabs-searchers",
    "featured-cards": "m-featured-cards",
    "carousel-tabs": "m-tabs-campaign",
    "card-destination-carousel": "m-card-destination-carousel",
    "features-list-traveltool": "m-features-list-traveltool",
    "newsletter-subscription-ttool": "m-newsletter-subscription",
    "footer-traveltool": "m-footer-traveltool"
  };

  const resolvedComponent = component.isMobile
    ? MobileComponentMapper.getMappedComponent(
      desktopToMobileComponents[component.code],
      props.environment
      )
    : ComponentMapper.getMappedComponent(component.code, props.environment);
  if (resolvedComponent) {
    return React.createElement(
      resolvedComponent,
      getComponentModel(
        component,
        models,
        component.isMobile,
        props.environment
      ),
      getComponentSlots(componentInstanceParentSlots, models, props.environment)
    );
  }
  return React.createElement("div", {});
}
export default DynamicRenderer;
