import React from 'react'

type TextProps = {
    children: JSX.Element|JSX.Element[];
    value: string;
  };

const Text = function ({ ...props }: TextProps) {
  return (
    <section>
      <p>Text component</p>
      <p>{props.value}</p>
      <div>{props.children}</div>
    </section>
  )
}

export default Text;
