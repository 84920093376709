import EventGalleryItem from "../event-gallery-item/event-gallery-item";
import { useEffect, useState } from "react";
import { EventGalleryBlockProps } from "./props/event-gallery-block.props";

export default function EventGalleryBlock({
    imageUrls,
}: Readonly<EventGalleryBlockProps>) {

    const [renderedObject, setRenderedObject] = useState(<div></div>);

    useEffect(() => {
        switch (imageUrls.length) {
            case 1:
                setRenderedObject(renderOneImage);
                break;
            case 2:
                setRenderedObject(renderTwoImages);
                break;
            default:
                setRenderedObject(renderMoreThanTwoImages);
                break;
        }
    }, [imageUrls]);

    const renderOneImage = <div className="images" data-testid="small-block">
        <div className="proposal-images">
            <EventGalleryItem
                className="image proposal-images__main full-width"
                imageId={imageUrls?.[0]}
                imageUrl={imageUrls?.[0]}
                images={imageUrls}
            >
                <div className="proposal-images__counter">
                    <div className="proposal-images__counter-number">
                        {imageUrls?.length}
                    </div>
                    <i className="ri-image-line" />
                </div>
            </EventGalleryItem>
        </div>
    </div>;

    const renderTwoImages = <div className="images" data-testid="medium-block">
        <div className="proposal-images">
            <EventGalleryItem
                className="image proposal-images__main half-width"
                imageId={imageUrls?.[0]}
                imageUrl={imageUrls?.[0]}
                images={imageUrls}
            >
                <div className="proposal-images__counter">
                    <div className="proposal-images__counter-number">
                        {imageUrls?.length}
                    </div>
                    <i className="ri-image-line" />
                </div>
            </EventGalleryItem>
            <EventGalleryItem
                className="image proposal-images__main half-width"
                imageId={imageUrls?.[1]}
                imageUrl={imageUrls?.[1]}
                images={imageUrls}
            />
        </div>
    </div>;

    const renderMoreThanTwoImages = <div className="images" data-testid="big-block">
        <div className="proposal-images">
            <EventGalleryItem
                className="image proposal-images__main"
                imageId={imageUrls?.[0]}
                imageUrl={imageUrls?.[0]}
                images={imageUrls}
            >
                <div className="proposal-images__counter">
                    <div className="proposal-images__counter-number">
                        {imageUrls?.length}
                    </div>
                    <i className="ri-image-line" />
                </div>
            </EventGalleryItem>
            <div className="proposal-images__secondary">
                <div className="proposal-images__secondary-top">
                    <EventGalleryItem
                        className="image bottom-right-row"
                        imageId={imageUrls?.[1]}
                        imageUrl={imageUrls?.[1]}
                        images={imageUrls}
                    />
                </div>
                <div className="proposal-images__secondary-bottom">
                    <EventGalleryItem
                        className="image bottom-right-row"
                        imageId={imageUrls?.[2]}
                        imageUrl={imageUrls?.[2]}
                        images={imageUrls}
                    />
                    {imageUrls.length > 3 &&
                        <EventGalleryItem
                            className="image bottom-right-row"
                            imageId={imageUrls?.[3]}
                            imageUrl={imageUrls?.[3]}
                            images={imageUrls}
                        />
                    }
                </div>
            </div>
        </div>
    </div>;

    return renderedObject;
}
