import { forwardRef } from "react";
import { HeaderImageProps } from "./props/header-image.props";
import "./header-image.css"
import ImageUrlProxyUtils from "../../../../utils/imageUrlProxy.utils";

export default forwardRef<HTMLElement, HeaderImageProps>(function HeaderImage({ Url }: Readonly<HeaderImageProps>) {
  const imageStyle = {
    backgroundImage: `url(${ImageUrlProxyUtils.processImageUrl(Url)})`
  }

  return (
    <div className="header-image" style={imageStyle} />
  );
}
);