import React, { ReactElement } from "react";
import "../price/price.css"
import { PricesProps } from "./props/prices.props";
import BasePrice from "../base-price/base-price";

export default function Prices(props: Readonly<PricesProps>) {
  const { items } = props;
  const priceElements: ReactElement[] = [];
  items.forEach((item) => {
    priceElements.push(
      <BasePrice
        amount={item.amount}
        amountWithFees={item.amountWithFees}
        bookButtonLiteral={item.bookButtonLiteral}
        currency={item.currency}
        currencySymbol={item.currencySymbol}
        description={item.description}
        isVisible={item.isVisible}
        link={item.link}
        title={item.title}
      />
    );
  });
  return (
    <div className="prices-block" data-testid="prices">
      <div className="price">
        <div className="price-box">{priceElements}</div>
      </div>
    </div>
  );
}
