import { createPortal } from "react-dom";
import { pdf } from "@react-pdf/renderer";
import MyDoc from "../../pdf/pdf";
import { SubcomponentProps } from "../../../props/subcomponent.props";
import WaitModal from "../../wait-modal/wait-modal";
import { toast } from "react-toastify";
import { PdfStyle, Settings } from "../../../props/side-menu.props";
import useModalState from "../../custom-hooks/use-modal-state";

type DownloadPdfdButtonType = {
  subComponentModels: SubcomponentProps[];
  literals: Map<string, string>;
  pdfStyles: PdfStyle;
  settings: Settings
};

export default function DownloadPdfdButton({
  subComponentModels,
  literals,
  pdfStyles,
  settings
}: Readonly<DownloadPdfdButtonType>) {
  const [showModal, { createModal, closeModal }] = useModalState();

  const generatePdf = async () => {
    try {
      createModal();
      const blob = await pdf(
        <MyDoc subComponentModels={subComponentModels} pdfStyles={pdfStyles} settings={settings}/>
      ).toBlob();
      const pdfURL = URL.createObjectURL(blob);
      window.open(pdfURL, "_blank");
    } catch (error) {
      console.log(error);
      toast.error(literals["ErrorGeneratePdf"]);
    }
    closeModal();
  };
  return (
    <>
      <button onClick={generatePdf} className="button pdf-button" data-testid="downloadPdfBtn" type="button">
        <span className="button__inner">
          {literals["Download"]}
          <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
            <path d="M3 19h18v2H3v-2zm10-5.828L19.071 7.1l1.414 1.414L12 17 3.515 8.515 4.929 7.1 11 13.17V2h2v11.172z" />
          </svg>
        </span>
      </button>
      {showModal && createPortal(<WaitModal />, document.body)}
    </>
  );
}
