import { useState } from "react";
import "./send-mail-modal.css";
import QuestionInput from "../../../interested-modal/question-item/question-input/question-input";
import { toast } from "react-toastify";
import sendEmail from "./services/send-mail.service";
import isValidInputMail from "./utils/send-mail.utils";

type SendMailModalProps = {
  onClose: () => void;
  productName: string;
  literals: Map<string, string>;
  token: string;
};

export default function SendMailModal({
  onClose,
  productName,
  literals,
  token
}: Readonly<SendMailModalProps>) {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const updateEmail = (_: string, value: string) => setEmail(value.trim());
  const updateMessage = (value: string) => setMessage(value.trim());

  const handleResponse = (res: boolean) => {
    if (res) {
      toast.success(literals["EmailSent"]);
      onClose();
    } else {
      toast.error(literals["ErrorSendEmail"]);
    }
  };

  const send = async () => {
    if (!isValidInputMail(email)) {
      toast.error(literals["InvalidEmailFormat"]);
    } else {
      setLoading(true);
      const apiUrl = window.location.origin;
      const url = window.location.href;
      const res = await sendEmail(
        email,
        url,
        productName,
        message,
        token,
        apiUrl
      );
      setLoading(false);
      handleResponse(res);
    }
  };

  return (
    <div
      style={{ pointerEvents: loading ? "none" : "auto" }}
      className="i-modal-container visible centered"
      data-testid="send-mail-modal"
    >
      <div className="i-modal medium">
        <div className="i-modal-header">
          <button
            onClick={onClose}
            className="button transparent i-modal-close-button"
            type="button"
          >
            <div className="button__inner">
              <svg
                className="remixicon-icon "
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
              </svg>
            </div>
          </button>
          <div className="content">{literals["ShareEmail"]}</div>
        </div>
        <div className="i-modal-body">
          <div className="i-modal-body-inner">
            <div className="i-modal-body-inner-box">
              <QuestionInput
                id={""}
                label={literals["DestinationEmail"]}
                required={true}
                type={"email"}
                callBack={updateEmail}
              />
              <label className="question-label">
                {literals["Message"]}
              </label>
              <div className="mogu-textarea__container">
                <textarea className="message" data-testid="message-text" onChange={(e) => updateMessage(e.target.value)} />
              </div>
            </div>
          </div>
        </div>
        <div className="i-modal-footer">
          <button
            onClick={onClose}
            onKeyDown={onClose}
            type="button"
            className="button transparent"
          >
            <div className="button__inner">{literals["Cancel"]}</div>
          </button>
          <button
            onClick={send}
            onKeyDown={send}
            type="button"
            className="button primary"
            data-testid="send"
          >
            <div className="button__inner">{literals["Send"]}</div>
          </button>
        </div>
      </div>
    </div>
  );
}
