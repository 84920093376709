import React, { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import TestRenderer from '../renderers/test-renderer';
import "../styles/App.css";
import "../styles/Home.scss";
import "../styles/Home.css";

type TestComponentProps = {
  name: string;
};

export default function TestComponent({ ...props }: TestComponentProps) {
  return (
    <Suspense>
      <HelmetProvider>
        {props && props.name ? <div>{TestRenderer(props.name)}</div> : null}
      </HelmetProvider>
    </Suspense>
  );
}