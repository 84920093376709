import React from "react";
import { AirportFlightProps } from "../props/airport-flight.props";

export default function FlightContentCard(props: Readonly<AirportFlightProps>) {
  const { airport, city, className, country, date, hour } = props;
  return (
    <div className={`flight-card__content-card ${className}`}>
      <div className="flight-card__content-card-airport">{city}</div>
      <div className="flight-card__content-card-airport-iata">{airport}</div>
      <div className="flight-card__content-card-airport">{`${city}, ${country}`}</div>
      <div className="flight-card__content-card-date">{date}</div>
      <div className="flight-card__content-card-time">{hour}</div>
    </div>
  );
}
