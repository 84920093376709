import { Suspense, useContext, useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import PageContext from './context';
import ComponentModelRs from '../dto/component/response/component-model-rs';
import Page from '../models/page';
import CssUtils from '../utils/css.utils';
import "../styles/Home.css";
import "../styles/Home.scss";
import "./Product.css";
import ProductError from '../components/product/error';
import ProductsNoLazyRenderer from '../renderers/products-no-lazy-renderer';

function Products() {
  const pageWithModels = useContext(PageContext);
  const [helmet, setHelmet] = useState<any>(null);
  const errorImage = "https://www.traveltool.es/traveltool/images/product-error.png";
  const errorText = "Este producto no está disponible";
  useEffect(() => {
    setHelmet(
      <Helmet>
        <link
          rel="shortcut icon"
          href="https://cdn.traveltool.es/traveltool/images/favicon.ico"
          type="image/x-icon"
        />
      </Helmet>
    );
  }, [pageWithModels]);

  return (
    <div data-testid="products">
      <Suspense>
        <main>
          <section
            style={CssUtils.injectPageCSSVariables(pageWithModels?.page as Page)}
          >
            {helmet}
            <div>
              {pageWithModels?.page?.layoutInstance
                ? pageWithModels.page.layoutInstance.componentInstanceLayoutInstances.map(
                  (componentInstanceLayoutInstance) => (
                    <div
                      key={
                        componentInstanceLayoutInstance.componentInstance.component
                          .code
                      }
                      id={
                        componentInstanceLayoutInstance.componentInstance.component
                          .code
                      }
                      style={CssUtils.injectComponentCSSVariables(
                        componentInstanceLayoutInstance.componentInstance
                      )}
                    >
                      {
                        ProductsNoLazyRenderer(
                          {
                            componentInstance: componentInstanceLayoutInstance.componentInstance,
                            models: pageWithModels.models as ComponentModelRs[],
                            environment: pageWithModels.environment as string
                          }
                        )
                      }
                    </div>
                  )
                )
                :
                <ProductError errorImage={errorImage} errorText={errorText} />
              }
            </div>
          </section>
        </main>
      </Suspense>
    </div>
  );
}

export default Products;