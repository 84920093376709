import { useState } from "react";

type QuestionTextAreaProps = {
  label: string;
  id: string;
  required: boolean;
  callBack: (type: string, value: string) => void;
};

export default function QuestionTextArea({
  label,
  id,
  required,
  callBack,
}: Readonly<QuestionTextAreaProps>) {
  const [counter, setCounter] = useState(0);

  return (
    <div className="mogu-textarea">
      <div className="mogu-textarea-label">
        <label className="question-label" aria-label={label} htmlFor={id}>
          {label}
        </label>
        <div className="form-question__required-indicator">*</div>
      </div>
      <textarea
        id={id}
        required={required}
        itemType="message"
        rows={5}
        maxLength={500}
        onChange={(e) => {
          setCounter(e.target.value.length);
          callBack("message", e.target.value);
        }}
      ></textarea>
      <div className="chars-counter">{counter} / 500</div>
    </div>
  );
}
