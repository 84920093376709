import { StyleSheet } from "@react-pdf/renderer";

const style = StyleSheet.create({
  container: {
    justifyContent: "space-between",
    alignContent: "center",
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    marginVertical: 20,
  },
  image: {
    width: "25%",
    borderRadius: "10px",
    flex: "1 0 30%",
    backgroundColor: "#e6e9ed",
  },
});

export default style;
