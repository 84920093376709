import React from 'react'

function TextImage() {
  return (
    <section>
      <p>Text_image component</p>
      <img
        style={{ width: "50%" }}
        alt=""
        src="https://app.smy.travel/assets/img/logo_smytravel_neg.svg"
      />
    </section>
  )

}

TextImage.defaultProps = {
  children: null
}

export default TextImage;
