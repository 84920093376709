import "./wait-modal.style.css";

export default function WaitModal() {
  return (
    <div className="wait-modal-container">
      <div className="wait-modal-loading-outer">
        <div className="wait-modal-loading-inner"></div>
      </div>
    </div>
  );
}
