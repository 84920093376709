import "./side-menu-mobile.css";
import CurrencyHelper from "../../../../helpers/currency.helper";
import DownloadPdfdButton from "../side-menu/download-pdf-button/download-pdf-button";
import { SideMenuMobileProps } from "../../props/side-menu-mobile.props";
import ShareButton from "../side-menu/share-button/share-button";

export default function SideMenuMobile(props: Readonly<SideMenuMobileProps>) {
  const {
    code,
    literals,
    minPrice,
    subComponentModels,
    pdfStyles,
    title,
    settings,
    token
  } = props;
  

  
  return (
      <div className="nav-container">
        <div className="actions">
          <button
            data-testid="checkOutBtn"
            type="button"
            id={`mogu-checkout_button_${code}`}
            className="button mogu__checkout-button checkout-button"
            data-tour={code}
            data-url={`https://app.moguplatform.com/checkout_embed?code=${code}`}
          >
            {literals["Book"]} ({minPrice.amount}&nbsp;
            {CurrencyHelper.formatCurrency(minPrice.currency)})
          </button>
          <div className="button-divider info-box"></div>
          {settings?.has_download_proposal ? <DownloadPdfdButton
            literals={literals}
            subComponentModels={subComponentModels}
            pdfStyles={pdfStyles}
            settings={settings}
          />: null}
          <div className="button-divider"></div>
        <ShareButton productName={title} literals={literals} token={token} />

        </div>
      </div>
  );
}
