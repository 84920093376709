import React from "react";
import { MapProps } from "../../props/map.props";
import "./map.css"

export default function MoguMap(props: Readonly<MapProps>) {
  const { link } = props;

  return (
    <div className="map">
      <iframe
        className="google-maps custom-map"
        width="100%"
        height="400"
        title="map"
        loading="lazy"
        src={link} />
    </div>
  );
}
