import React, { ReactElement } from 'react';
import ComponentModelRs from '../dto/component/response/component-model-rs';
import ComponentInstance from '../models/component-instance';
import getComponentModel from './base-renderer';
import MobileProductsComponentMapper from '../mappers/mobile-products-component.mapper';
import ProductsComponentMapper from '../mappers/products-component.mapper';


type ProductsNoLazyRendererProps = {
  componentInstance: ComponentInstance;
  models: ComponentModelRs[],
  environment: string
};

function ProductsNoLazyRenderer(
  {...props}: ProductsNoLazyRendererProps
): ReactElement {
  const { componentInstance, models, environment } = props;
  const { component } = componentInstance;

  const desktopToMobileComponents = {
    "header-traveltool": "m-header-traveltool",
    "footer-traveltool": "m-footer-traveltool"
  };

    const resolvedComponent = component.isMobile
    ? MobileProductsComponentMapper.getMappedComponent(
        desktopToMobileComponents[component.code],
        props.environment
      )
    : ProductsComponentMapper.getMappedComponent(component.code, environment);

  if (resolvedComponent) {
    return React.createElement(
      resolvedComponent,
      getComponentModel(component, models, component.isMobile, environment)
    );
  }
  return React.createElement("div", {});
}
export default ProductsNoLazyRenderer;
