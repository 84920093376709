import { View, Text } from "@react-pdf/renderer";
import style from "./pdf-price.style";
import PdfText from "../pdf-text/pdf-text";
import { PdfPriceProps } from "./props/pdf-price.props";

export default function PdfPrice({
  amount,
  description,
  title,
  pdfStyles,
}: Readonly<PdfPriceProps>) {
  return (
    <View style={style.container}>
      <View style={style.titleContainer}>
        <Text style={{...style.title, color: pdfStyles.primaryColor700 ?? "#000000"}}>{title}</Text>
        <Text style={{ color: pdfStyles.tertiaryColor500 ?? "" }}>{amount}€</Text>
      </View>
      {description && <PdfText Text={description} pdfStyles={pdfStyles} />}
    </View>
  );
}
