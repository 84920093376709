import { StyleSheet } from "@react-pdf/renderer";

const style = StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10,
    alignItems: 'center',
    marginTop: 25
  },
  title: {
    fontSize: 18,
    fontWeight: 'heavy'
  },
  date: {
    color: 'black',
    fontSize: 14
  }
})

export default style;