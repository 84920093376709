import { lazy } from "react";

import MFooterTraveltoolComponent from "../components/alexandria/mobile/m-footer-traveltool/m-footer-traveltool";
import Product from "../components/product/product";

const mobileComponents = {
  "m-footer-traveltool": MFooterTraveltoolComponent,
  "m-header-traveltool": lazy(
    () => import("../components/mobile-header/header")
  ),
  product: Product,
};

export default class MobileProductComponentMapper {
  static getMappedComponent(componentName: string, environment: string) {
    if (
      componentName === "m-header-traveltool" &&
      environment === "production"
    ) {
      const component = lazy(
        () =>
          import(
            "../components/alexandria/mobile/m-header-traveltool/m-header-traveltool"
          )
      );
      return component;
    }
    return mobileComponents[componentName];
  }
}
