import { FileProps } from "./props/file.props";
import "./file.css";

export default function File({ Id, Name, Url }: Readonly<FileProps>) {
  return (
    <div className="file-container" data-testid="file">
      <div className="file-box">
        <svg
          className="remixicon-icon"
          width="24"
          height="24"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M9 2.003V2h10.998C20.55 2 21 2.455 21 2.992v18.016a.993.993 0 0 1-.993.992H3.993A1 1 0 0 1 3 20.993V8l6-5.997zM5.83 8H9V4.83L5.83 8zM11 4v5a1 1 0 0 1-1 1H5v10h14V4h-8z"></path>
        </svg>
        <a
          href={Url}
          target="_blank"
          rel="noreferrer noopener"
          download=""
          className="file-url"
        >
          {Name}
        </a>
        <a
          href={Url}
          target="_blank"
          rel="noreferrer noopener"
          download=""
          className="file-url-icon"
        >
          <svg
            className="remixicon-icon"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M3 19h18v2H3v-2zm10-5.828L19.071 7.1l1.414 1.414L12 17 3.515 8.515 4.929 7.1 11 13.17V2h2v11.172z"></path>
          </svg>
        </a>
      </div>
    </div>
  );
}
