import { Text, View } from "@react-pdf/renderer";
import style from "./pdf-top.style";
import { PdfTopProps } from "./props/pdf-top.props";

export default function PdfTop({
  phone,
  name,
  secondaryColor600,
}: Readonly<PdfTopProps>) {
  return (
    <View 
      fixed
      style={{
        ...style.top,
        backgroundColor: secondaryColor600 ?? "#150A44",
      }}
    >
      {name && <Text>{name}</Text>}
      {phone && <Text>{phone}</Text>}
    </View>
  );
}
