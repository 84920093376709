class ImageUrlProxyUtils {
    static readonly urlDictionary: Record<string, string> = {
        "https://mogu-platform-app.s3.eu-west-1.amazonaws.com" : "/moguaws",
        "https://static.moguplatform.com" : "/mogustatic",
        "https://mogu-cruises-photos.s3.eu-west-1.amazonaws.com" :"/mogucruisesphotos"
    };
    static processImageUrl(imageUrl: string) {
        if (imageUrl === undefined || imageUrl.length == 0) {
            return "";
        }
        for(const key in this.urlDictionary){
            if (imageUrl.indexOf(key) != -1) {
                return imageUrl.replace(key , this.urlDictionary[key]);
            }
        }
        return imageUrl;
      }
}
export default ImageUrlProxyUtils;
