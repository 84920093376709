import { QuestionItemProps } from "./props/question-item.props";
import QuestionInput from "./question-input/question-input";
import "./question-item.css";
import QuestionMultipleChoices from "./question-multiple-choices/question-multiple-choices";
import QuestionTextArea from "./question-text-area/question-text-area";

export default function QuestionItem({
  question,
  callBack,
}: Readonly<QuestionItemProps>) {
  const renderInputs = () => {
    if (question.type === "name" || question.type == "email")
      return (
        <QuestionInput
          id={question.id}
          label={question.label}
          required={question.required}
          type={question.type}
          callBack={callBack}
        />
      );

    if (question.type === "message")
      return (
        <QuestionTextArea
          id={question.id}
          label={question.label}
          required={question.required}
          callBack={callBack}
        />
      );

    if (question.type === "multiple-choices")
      return (
        <QuestionMultipleChoices
          callBack={callBack}
          choices={question.choices}
          label={question.label}
        />
      );

    return null;
  };

  return (
    <div className="question-container">
      <div className="stripe__form-question">{renderInputs()}</div>
    </div>
  );
}
