import { View, Text, Image, Svg, Path } from "@react-pdf/renderer";
import style from "./pdf-flight.style";
import { FlightProps } from "../../../event/props/flight.props";
import PdfFlightContentCard from "./pdf-flight-content/pdf-flight-content";

export default function PdfFlight(props: Readonly<FlightProps>) {
  const {
    airlineLogo,
    arrival,
    departure,
    airlineName,
    flightNumber,
    flightTime,
  } = props;
  return (
    <View style={style.container}>
      <View style={style.headerContainer}>
        <Image style={style.logo} src={airlineLogo} />
        <Text>{airlineName}</Text>
        <Text style={style.flightNumber}>{flightNumber}</Text>
      </View>
      <View style={style.flightContentContainer}>
        <PdfFlightContentCard
          airport={departure.airport}
          city={departure.city}
          country={departure.country}
          date={departure.date}
          hour={departure.hour}
          className="left"
        />
        <View style={style.middleContentContainer}>
          <Text style={{ color: "#152b5b", fontSize: 12 }}>{flightTime}</Text>
          <Svg style={{ width: 14, height: 14 }} viewBox="0 0 24 24">
            <Path
              fill="#475569"
              d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
            />
          </Svg>
        </View>
        <PdfFlightContentCard
          airport={arrival.airport}
          city={arrival.city}
          country={arrival.country}
          date={arrival.date}
          hour={arrival.hour}
          className="right"
        />
      </View>
    </View>
  );
}
