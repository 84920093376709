import { useEffect, useState } from "react";
import { BaseModel } from "./props/base-model.props";
import { injectStyle } from "react-toastify/dist/inject-style";
import CarouselBanner from "./subcomponents/carousel-banner/carousel-banner";
import ProductCard from "./subcomponents/product-card/product-card";
import ProductsResponse from "./dto/response/ProductsBaseResponse";
import "./products.css";
import Pagination from "../pagination/pagination";
import ProductsService from "../../services/products.service";
import WaitModal from "../product/subcomponents/wait-modal/wait-modal";

export default function Products(props: Readonly<BaseModel>) {
  const [currentPage, setCurrentPage] = useState(1);
  const [model, setModel] = useState<ProductsResponse>();
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      injectStyle();
    }
  }, []);

  const onPageChange = (pageNumber: (string | number)) => {
    if (typeof pageNumber !== "string") {
      setCurrentPage(pageNumber);
    }
  }

  useEffect(() => {
    const { data } = props;
    if (data) {
      setModel(new ProductsResponse(data));
    }
  }, [props])

  useEffect(() => {
    if (model?.title) {
      setWaiting(true);
      ProductsService.getProductsByPage(currentPage)
        .then(response => {
          setModel(response)
          setWaiting(false);
        });
    }
  }, [currentPage])

  if (!model) {
    return <div />;
  }

  return (
    <div data-testid="products">
      <CarouselBanner products={model.productsData} />
      <div className="products-container">
        <h1>{model.title && model.title != "" ? model.title : "Nuestras ofertas especiales"}</h1>
        <div className="product-cards-container">
          {waiting ? <WaitModal /> :
            model.productsData.map((product) => {
              return <ProductCard key={product.Slug} {...product} />
            })
          }
        </div>
        <Pagination
          siblingCount={1}
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={model.totalProducts}
          pageSize={10}
          onPageChange={page => onPageChange(page)}
        />
      </div>
    </div>
  );
}
