import { Day, ItineraryProps } from "./props/itinerary.props";
import "./itinerary.css"
import ItineraryElement from './itinerary-element/itinerary-element';
import { States } from "./itinerary-element/itinerary-states";

export default function Itinerary(props: Readonly<ItineraryProps>) {
  return (
    <div data-testid="itinerary" className="itineraries-container">
      <div className="itineraries-box">
        {props?.Days?.map((x: Day, index: number) =>
          <ItineraryElement
            key={x.id} day={x}
            defaultExpanded={getItineraryState(index,props.DefaultState)}
          />)}
      </div>
    </div>
  );

  function getItineraryState(index: number, state: States): boolean {
    
    if (state == States.First_Expanded && index == 0) return true;
    if (state == States.Expanded) return true;

    return false;
  }
}
