import React from "react";
import "./price.css"
import { PriceProps } from "./props/price.props";
import BasePrice from "../base-price/base-price";

export default function Price(props: Readonly<PriceProps>) {
  const {
    amount,
    amountWithFees,
    bookButtonLiteral,
    currency,
    currencySymbol,
    description,
    link,
    isVisible,
    title,
  } = props;
  return (
    <div data-testid="price" className="price">
      <div className="price-box">
        <BasePrice
          amount={amount}
          bookButtonLiteral={bookButtonLiteral}
          currency={currency}
          description={description}
          link={link}
          title={title}
          amountWithFees={amountWithFees}
          currencySymbol={currencySymbol}
          isVisible={isVisible}
        />
      </div>
    </div>
  );
}
