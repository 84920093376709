import { Image, View } from "@react-pdf/renderer";
import style from "./pdf-gallery.style";
import { GalleryProps } from "../../../gallery/props/gallery.props";
import ImageUrlProxyUtils from "../../../../../../utils/imageUrlProxy.utils";

export default function PdfGallery({ Items }: Readonly<GalleryProps>) {
  return (
    <View style={style.container}>
      {Items.slice(0,3).map((x) => (
        <Image style={style.image} key={x.id} src={ImageUrlProxyUtils.processImageUrl(x.image_url)} />
      ))}
    </View>
  );
}
