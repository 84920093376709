import axios from "axios";

export default async function sendEmail(
  email: string,
  url: string,
  productName: string,
  message: string,
  token: string,
  apiUrl: string
): Promise<boolean> {
  try {
    const { data } = await axios.post<boolean>(`${apiUrl}/email/send`, {
      email,
      url,
      productName,
      message,
      token,
    });
    return data;
  } catch (error) {
    console.error(`Error sending mail. Error: ${error}`);
    return false;
  }
}
