import { useEffect, useState } from "react";

type ModalStateActions = {
  createModal: () => void;
  closeModal: () => void;
};

const useModalState = (): [boolean, ModalStateActions] => {
  const [showModal, setShowModal] = useState(false);

  const createModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  useEffect(() => {
    document.body.style.overflow = showModal ? "hidden" : "auto";
  }, [showModal]);

  return [showModal, { createModal, closeModal }];
};

export default useModalState;
