import { Image, View } from "@react-pdf/renderer";
import style from "./pdf-logo.style";
import { PdfLogoProps } from "./props/pdf-logo.props";
import ImageUrlProxyUtils from "../../../../../../utils/imageUrlProxy.utils";

export default function PdfLogo({ logoUrl }: Readonly<PdfLogoProps>) {
  console.log(logoUrl);
  return (
    <View style={style.container}>
      { <Image style={style.image}  src={ImageUrlProxyUtils.processImageUrl(logoUrl)}/>}
    </View>
  );
}
