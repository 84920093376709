import { CarouselBannerProps } from "./props/carousel-banner.props";
import "./carousel-banner.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Banner from "../banner/banner";
import { useEffect, useState } from "react";

export default function CarouselBanner({ ...props }: Readonly<CarouselBannerProps>) {

  const SampleNextArrow = (props : any) => {
    const { className, style, onClick } = props;
    return (
      <button
        className={className}
        style={{ ...style }}
        onClick={onClick}
        ><i className={"nico-angle-right"}/></button>
    );
  }
  
  const SamplePrevArrow = (props : any) => {
    const { className, style, onClick } = props;
    return (
      <button
        className={className}
        style={{ ...style}}
        onClick={onClick}
      ><i className={"nico-angle-left"}/></button>
    );
  }

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 6000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  const [carouselType, setCarouselType] = useState<string>('none');

  useEffect(() => {
    if (!props.products || props.products.length == 0) {
      setCarouselType('none');
    } else if (props.products.length == 1) {
      setCarouselType('banner');
    } else {
      setCarouselType('carousel');
    }
  }, [props.products])

  return (
    <div data-testid="carouselBanner" id="carousel-banner">
      {carouselType == 'banner' && <Banner key={0} {...props.products[0]} />}
      {carouselType == 'carousel' &&
        <Slider {...settings} >
          {props.products.map((product) => {
            return (<Banner key={props.products.indexOf(product)} {...product} />
            );
          })}
        </Slider>
      }
    </div>
  );
}