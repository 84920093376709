import React, { ForwardedRef, forwardRef } from "react";
import { SectionProps } from "./props/section.props";
import "./section.css"

export default forwardRef<HTMLElement, SectionProps>(function Section(
  { title }: Readonly<SectionProps>,
  ref: ForwardedRef<any>
) {
  return (
    <div className="section" ref={ref}>
        <h4>{title}</h4>
    </div>
  );
});
