import React, { Suspense, useContext, useEffect } from 'react';
import { Helmet } from "react-helmet";
import Cookies from 'universal-cookie';
import * as _ from 'lodash';
import DynamicRenderer from '../renderers/dynamic-renderer';
import PageContext from './context';
import ComponentModelRs from '../dto/component/response/component-model-rs';
import Page from '../models/page';
import CssUtils from '../utils/css.utils';
import "../styles/Home.css";
import "../styles/Home.scss";
import GeolocationService from '../services/geolocation.service';
import SessionService from '../services/session.service';

export default function Home() {
  const cookies = new Cookies();
  const pageWithModels = useContext(PageContext);

  let helmet = null;
  if (pageWithModels) {
    helmet =
      <Helmet>
        <link
          rel="shortcut icon"
          href="https://cdn.traveltool.es/traveltool/images/favicon.ico"
          type="image/x-icon"
        />
      </Helmet>;
  }

  useEffect(() => {
    if (navigator?.geolocation !== undefined) {
      const geoloc = cookies.get("airport_geolocation");
      if (!geoloc || geoloc !== 1) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            const getOrigin = async () => {
              const language = cookies.get("ck_idioma");
              if (language) {
                const geolocationResponse =
                  await GeolocationService.getOriginAirportByCoordinates(
                    position.coords.latitude,
                    position.coords.longitude,
                    language as string
                  );
                if (
                  geolocationResponse?.originCode &&
                  geolocationResponse?.originCode !== "---"
                ) {
                  cookies.set("aeropuerto", geolocationResponse.originCode, {
                    path: "/",
                    secure: true,
                    httpOnly: true
                  });
                  SessionService.setOriginAirportInSession(
                    geolocationResponse.originCode
                  );
                  cookies.set("airport_geolocation", 1, {
                    path: "/",
                    secure: true
                  });
                }
              }
            };
            getOrigin();
          },
          function (positionError) {
            console.log(positionError.message);
          }
        );
      }
    }
  }, []);
  return (
    <Suspense>
      <main>
        <section
          style={CssUtils.injectPageCSSVariables(pageWithModels?.page as Page)}
        >
          {helmet}
          <div>
            {pageWithModels?.page && pageWithModels.page.layoutInstance
              ? pageWithModels.page.layoutInstance.componentInstanceLayoutInstances.map(
                  (componentInstanceLayoutInstance) => (
                    <Suspense>
                      <div
                        id={
                          componentInstanceLayoutInstance.componentInstance.component
                            .code
                        }
                        style={CssUtils.injectComponentCSSVariables(
                          componentInstanceLayoutInstance.componentInstance
                        )}
                      >
                      {DynamicRenderer(
                          {
                            componentInstance: componentInstanceLayoutInstance.componentInstance,
                            models: pageWithModels.models as ComponentModelRs[],
                            environment: pageWithModels.environment as string
                          }
                        )}
                      </div>
                    </Suspense>
                  )
                )
              : null}
          </div>
        </section>
      </main>
    </Suspense>
  );
}