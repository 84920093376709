import { ProductInfoProps, CardPriceProposal } from "./props/product-card.props";
import "./product-card.css";
import DOMPurify from "isomorphic-dompurify";

export default function ProductCard({ ...props }: Readonly<ProductInfoProps>) {
  const allowedTags = {
    ALLOWED_TAGS: [
      'p',
      'strong',
      'i',
      'a',
      'ol',
      'li',
      'ul',
      'figure',
      'div',
      'oembed',
      'table',
      'tbody',
      'thead',
      'tr',
      'th',
      'td',
      'iframe',
      'br'
    ]
  }
  const {
    ImageUrl,
    Title,
    StartDate,
    EndDate,
    NumDays,
    Description,
    ProposalPrice,
    Slug
  } = props;

  let priceFromToString: { [id: number]: string } = {};
  priceFromToString[CardPriceProposal.from] = ProposalPrice.split(' ')[0] ?? '';
  priceFromToString[CardPriceProposal.to] = ProposalPrice.replace(priceFromToString[CardPriceProposal.from], '') ?? '';

  return (
    <div className="product-card">
      <a title={Title} href={'../proposals/' + Slug} target="_blank" className="product-link"></a>
      <div className="img-container">
        <img src={ImageUrl} alt="Imagen Producto" />
      </div>
      <div className="content">
        <h3>{Title}</h3>
        <div className="duration">
          <p>{NumDays + "  |  "}</p>
          <p>{StartDate ? StartDate + "  -  " : ""}</p>
          <p>{EndDate}</p>
        </div>
        <div className="description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Description, allowedTags) }} />
      </div>
      <p className="show-more">Ver más</p>
      {priceFromToString[CardPriceProposal.to].trim() != '' &&
        <div className="proposal-price">
          <p className="from">{priceFromToString[CardPriceProposal.from]}</p>
          <p className="to">{priceFromToString[CardPriceProposal.to]}</p>
        </div>
      }
    </div>
  );
}